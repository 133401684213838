import React, { useEffect, useState } from "react";
import { useTableStyles } from "../../theme/table.style";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Tooltip,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import clsx from "clsx";
import { Empty } from "../empty";
import { formatDate, statusFormat } from "../../helpers/utils";
import Avatar from "@material-ui/core/Avatar";
import { useTranslation } from "react-i18next";
import { ColumnCustomizer } from "../columnCustomizer";
import { useSelector, useDispatch } from "react-redux";
import { updateTableConfig } from "../../store/setting/setting.action";
import { DeleteInvList, loadInventoryLists } from "../../store/inventory/inventory.action";
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: "pointer",
  },
  avatar: {
    width: 24,
    height: 24,
  },
  avatarCell: {
    width: 24,
  },
}));

export const SimpleTable = ({
  title,
  columns,
  data,
  hasPaper,
  isRowClickable,
  handleRowClick,
  rowPrimaryKey,
  avatar,
  listType,
}) => {
  
  const tableclasses = useTableStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableConfigs = useSelector((state) => state.Setting.tableConfigs);
  const { t } = useTranslation([
    "common",
    "product",
    "inventory",
    "clients",
    "transfert",
  ]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const invLists = useSelector((state) => state.Inventory.invLists);
  const [message, setMessage] = useState("");

  const [columnsData, setColumnsData] = useState({
    tableName: title,
    columns: columns,
  });
  const [open, setOpen] = useState(false);
  const handleDelete = (id) => {
    dispatch(DeleteInvList(id)).then(() => {
      // Reload the inventory list state after successful deletion
      dispatch(loadInventoryLists());
      setOpen(false); // Close the dialog after deletion
    });
  };
  
  var isInProgress = (item) => {
    if (invLists && Array.isArray(invLists)) {
      let itemIndex = invLists.findIndex(
        (i) => i.status == "In Progress" && i.products.includes(item)
      );
      if (itemIndex != -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleOpenTooltip = (row) => {
    setMessage("");
    if (isInProgress(row.item_code)) {
      setMessage(
        `${t("inventory:refItem")} ${row.item_code} ${t(
          "inventory:Inprogress"
        )}`
      );
      return;
    }
    return;
  };

  const tableConfigIndex = tableConfigs.findIndex(
    (el, i) => el.tableName == columnsData.tableName
  );

  useEffect(() => {
    const tableConfigIndex = tableConfigs.findIndex(
      (el, i) => el.tableName == columnsData.tableName
    );
    if (tableConfigIndex === -1) {
      columnsData &&
        dispatch(updateTableConfig([...tableConfigs, columnsData]));
    }
  }, [columnsData]);
  const alertList = (
    <Alert severity="warning">
      <AlertTitle>{t("common:warning")}</AlertTitle>
      {message}
    </Alert>
  );
  const formatCellValue = (column, row, t, globalDefaults) => {
    const value = row[column.key];

    if (column.key === 'sous_categorie') {
      return value || '-';
    }
  
    if (column?.title?.toLowerCase()?.includes("statut")) {
      return statusFormat(value)?.value;
    }
  
    if (column?.title?.toLowerCase()?.includes("date")) {
      return formatDate(value, "YYYY-MM-DD");
    }
  
    if (
      column?.key?.toLowerCase()?.includes("rate") ||
      column?.key?.toLowerCase()?.includes("total") ||
      column?.key?.toLowerCase()?.includes("amount") ||
      column?.key?.toLowerCase()?.includes("balance")
    ) {
      return (
        value?.toFixed(3) +
        " " +
        (row.currency ? row.currency : globalDefaults?.default_currency)
      );
    }
  
    if (column?.key?.toLowerCase()?.includes("is_stock_item")) {
      return value == 1 ? t("product:inStock") : t("product:outOfStock");
    }
  
    // Check for "Material Transfer" and return translation
    if (value === "Material Transfer") {
      return t("common:TransfertDeMateriel");
    }
    if(value === "Material Receipt"){
      return t("common:MaterialReceipt");
    }
    if(value === "Manufacture"){
      return t("common:Manufacture");
    }
    if(value === "Material Consumption for Manufacture"){
      return t("common:MaterialConsumptionforManufacture");
    }
    if(value === "Material Issue"){
      return t("common:MaterialIssue");
    }
    if(value === "Material Transfer for Manufacture"){
      return t("common:MaterialTransferforManufacture");
    }
    if(value === "Repack"){
      return t("common:Repack");
    }
     
    
     
  
    // Default return value
    return value ?? "-";
  };
  
  return (
    <Box
      className={hasPaper ? tableclasses.paper : null}
      style={{ width: "100%" }}
    >
      <ColumnCustomizer tableConfigIndex={tableConfigIndex} />
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {avatar && (
              <TableCell className={tableclasses.tablecellHeader}></TableCell>
            )}
            {columns &&
              tableConfigs[tableConfigIndex]?.columns?.map(
                (column, i) =>
                  ((!column?.isInView && column?.isInView !== 0) ||
                    column?.isInView === 1) && (
                    <TableCell
                      key={i}
                      className={tableclasses.tablecellHeader}
                      align="left"
                    >
                      {t(column.title)}
                    </TableCell>
                  )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
  {data &&
    Array.isArray(data) &&
    data.length !== 0 &&
    data.map((row, i) => (
      <Tooltip
        title={listType === "Total" && message ? alertList : ""}
        onMouseEnter={() => handleOpenTooltip(row)}
        key={i}
      >
        <TableRow
          className={
            isRowClickable
              ? clsx(classes.pointer, tableclasses.tableRow)
              : tableclasses.tableRow
          }
          onClick={() =>
            isRowClickable && handleRowClick(row[rowPrimaryKey])
          }
        >
          {avatar && (
            <TableCell
              className={clsx(
                tableclasses.tableCell,
                classes.avatarCell
              )}
              scope="row"
            >
              <Avatar className={classes.avatar} src="/img/user.svg" />
            </TableCell>
          )}
          {columns &&
            tableConfigs[tableConfigIndex]?.columns?.map(
              (column, index) =>
                ((!column?.isInView && column?.isInView !== 0) ||
                  column?.isInView === 1) && (
                  <TableCell
                    key={index}
                    className={
                      column?.key === "status"
                        ? clsx(
                            tableclasses.tableCell,
                            tableclasses[statusFormat(row.status).color]
                          )
                        : tableclasses.tableCell
                    }
                    align="left"
                  >
                  
                    {/* Render Cell Value */}
                    {column.key === "actions" ? (
                        <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleDelete(row[rowPrimaryKey]);
                        }}
                        style={{ color: "#e74c3c" }}
                        aria-label={t("common:delete")}
                      >
                        <DeleteIcon />
                      </IconButton>
                       
                       
                    ) : (
                      formatCellValue(column, row, t, globalDefaults)
                    )}
                  </TableCell>
                )
            )}
        </TableRow>
      </Tooltip>
    ))}
</TableBody>

      </Table>
      {data && data.length === 0 && <Empty hasPaper={hasPaper} />}
    </Box>
  );
};
