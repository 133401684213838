import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as localforage from "localforage";
import moment from "moment";
import { Empty } from "../../components/empty";
import { Filter } from "../../components/filter";
import { Loader } from "../../components/loader";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../helpers/helper";
import { InvoicesTableTicketTotal } from "./components/invoicestable/invoiceTableTicketTotal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  tabs: {
    "& .MuiTabs-scroller": {
      padding: "0px 30px",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #ECF2F5",
    },
  },
  skeleton: {
    marginBottom: "1rem",
  },
}));

export const Tickets = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const [invoice, setInvoice] = useState([]);
  const [filtredTickets, setFiltredTickets] = useState([]);
  const [filters, setFilters] = useState({
    status: [],
  });
  const [invoicepage, setInvoicepage] = useState(1);
  const [searchOrder, setSearchOrder] = useState("");

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation(["common", "order"]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };

  const fetchInvoices = async () => {
    setLoading(true); // Start loading
    const cachedData = await localforage.getItem("reduxPersist:Pos");
    const posData = cachedData ? JSON.parse(cachedData) : null;
    const posProfile = posData?.open?.pos_profile;
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "POS Invoice",
          fields: ["*"],
          filters: [
            ["POS Invoice", "posting_date", "Between", ["2024-01-01", moment().format("YYYY-MM-DDTHH:mm:ss")]],
            ["POS Invoice", "pos_profile", "=", posProfile],
            ["POS Invoice", "company", "=", globalDefaults?.default_company],
          ],
        }),
      });
      const data = await res.json();
      if (Array.isArray(data)) {
        setInvoice(data);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const HandleSearchOrder = (event) => {
    setSearchOrder(event.target.value);
    setFiltredTickets(
      GlobalfilterData(invoice?.slice(0)?.reverse(), event.target.value, [
        "posting_date",
        "name",
        "customer_name",
        "custom_ref",
        "grand_total",
      ])
    );
    setInvoicepage(1);
  };

  const HandleFilter = (key, item) => {
    setInvoicepage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setFiltredTickets(
      invoice?.slice(0)?.reverse().filter(FilterBy(FilterDataBy))
    );
  };

  useEffect(() => {
    setFiltredTickets(invoice?.slice(0)?.reverse());
  }, [invoice]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <Box>
      <Box className={classes.header}>
        <FormControl className={classes.formControl} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={searchOrder}
            placeholder={t("order:orderSearch")}
            onChange={HandleSearchOrder}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box>
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={(event, newValue) => setValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab className={classes.tab} label={t("order:tickets")} {...a11yProps(0)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          {loading ? (
            // Skeleton Loading Placeholders
            <>
              {Array.from(new Array(15)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rect"
                  height={50}
                  className={classes.skeleton}
                />
              ))}
            </>
          ) : (
            <>
              <Filter
                data={GetFilterItems(
                  [...new Set(filtredTickets.map((item) => item.status))],
                  { Paid: "Paid", Return: "Return", 0: "-*-***-**-*-**-*-" }
                )}
                title={"Statut"}
                id={"status"}
                HandleFilter={HandleFilter}
                value={filters.status}
                number={filters.status.length}
              />
              <InvoicesTableTicketTotal invoice={invoice} />
              {Math.ceil(filtredTickets.length / 10) > 0 && (
                <Box display="flex" justifyContent="center" m={4}>
                  <Pagination
                    onChange={(event, newPage) => setInvoicepage(newPage)}
                    page={invoicepage}
                    count={Math.ceil(filtredTickets.length / 10)}
                    color="primary"
                  />
                </Box>
              )}
              {filtredTickets && filtredTickets.length === 0 && (
                <Empty text={t("order:emptyMsgTicket")} />
              )}
            </>
          )}
        </TabPanel>
      </Box>
    </Box>
  );
};
