import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import clsx from "clsx";
import { useSelector } from "react-redux";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { Button, CircularProgress } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import TicketTotal from "../../../../components/ticketTotal/ticketTotal";
import TicketTotalDuplicata from "../../../../components/ticketTotal/ticketTotalDuplicata";
import axiosInstance from "../../../../history/axiosInstance";
import { formatDate } from "../../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 14,
    backgroundColor: theme.palette.productsTable.button,
    color: theme.palette.productsTable.buttonColor,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
    },
  },
  preparedButton: {
    fontSize: 14,
    backgroundColor: "#4caf50", // Green for "Imprimer" button
    color: "#fff",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
  print: {
    display: "none",
  },
}));

export const InvoicesTableTicketTotal = (props) => {
  const classes = useStyles();
  
  const [selectedRowFacture, setSelectedRowFacture] = useState(null); // For Facture
  const [selectedRowDuplicata, setSelectedRowDuplicata] = useState(null); // For Duplicata
  const [invoiceFacture, setInvoiceFacture] = useState(null); // Facture data
  const [invoiceDuplicata, setInvoiceDuplicata] = useState(null); // Duplicata data
  const [isPreparingFacture, setIsPreparingFacture] = useState(false); // Loading for Facture
  const [isPreparingDuplicata, setIsPreparingDuplicata] = useState(false); // Loading for Duplicata
const[company, setCompany] = useState(null);
  const componentRefTicketTotal = useRef();
  const componentRefTicketDuplicata = useRef();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };

  const handlePrintFacture = useReactToPrint({
    content: () => componentRefTicketTotal.current,
    onAfterPrint: () => {
      setSelectedRowFacture(null);
      setInvoiceFacture(null);
    },
  });

  const handlePrintDuplicata = useReactToPrint({
    content: () => componentRefTicketDuplicata.current,
    onAfterPrint: () => {
      setSelectedRowDuplicata(null);
      setInvoiceDuplicata(null);
    },
  });
  const loadCompany = async () => {
      
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Company%20&name=${globalDefaults?.default_company}`,
        { headers: header }
      );
      setCompany(data?.docs[0]);
    } catch (error) {
      console.error("Error fetching company:", error);
    }
  };
  useEffect(() => {
    loadCompany();
 
}, []);
  const loadInvoice = async (row, type) => {
    if (type === "facture") {
      setIsPreparingFacture(true);
      try {
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_API_URI}/api/details?doctype=POS%20Invoice&name=${row.name}`,
          { headers: header }
        );
        setInvoiceFacture(data?.docs[0]);
        setSelectedRowFacture(row);
      } catch (error) {
        console.error("Error fetching facture:", error);
      } finally {
        setIsPreparingFacture(false);
      }
    } else if (type === "duplicata") {
      setIsPreparingDuplicata(true);
      try {
        const { data } = await axiosInstance.get(
          `${process.env.REACT_APP_API_URI}/api/details?doctype=POS%20Invoice&name=${row.name}`,
          { headers: header }
        );
        setInvoiceDuplicata(data?.docs[0]);
        setSelectedRowDuplicata(row);
      } catch (error) {
        console.error("Error fetching duplicata:", error);
      } finally {
        setIsPreparingDuplicata(false);
      }
    }
  };

  return (
    <Box>
      <Table className={clsx()}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Ref Intn</TableCell>
            <TableCell align="left">Ref Extern</TableCell>
            <TableCell align="left">Client</TableCell>
            <TableCell align="left">Statut</TableCell>
            <TableCell align="left">Total Price</TableCell>
            <TableCell align="left">Facture</TableCell>
            <TableCell align="left">Duplicata</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.invoice.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{formatDate(row.posting_date, "YYYY-MM-DD")}</TableCell>
              <TableCell align="left">{row.name.substr(15, 5)}</TableCell>
              <TableCell align="left">{row.name ?? "-"}</TableCell>
              <TableCell align="left">{row.customer_name}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{(row.grand_total+0.1).toFixed(2)}</TableCell>
              <TableCell align="left">
                {selectedRowFacture?.name === row.name && invoiceFacture ? (
                  <Button
                    className={classes.preparedButton}
                    onClick={handlePrintFacture}
                  >
                    Imprimer Facture
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    onClick={() => loadInvoice(row, "facture")}
                    disabled={isPreparingFacture && selectedRowFacture?.name === row.name}
                  >
                    {isPreparingFacture && selectedRowFacture?.name === row.name ? (
                      <>
                        Préparation <CircularProgress size={16} style={{ marginLeft: "8px" }} />
                      </>
                    ) : (
                      "Préparer Facture"
                    )}
                  </Button>
                )}
              </TableCell>
              <TableCell align="left">
                {selectedRowDuplicata?.name === row.name && invoiceDuplicata ? (
                  <Button
                    className={classes.preparedButton}
                    onClick={handlePrintDuplicata}
                  >
                    Imprimer Duplicata
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    onClick={() => loadInvoice(row, "duplicata")}
                    disabled={isPreparingDuplicata && selectedRowDuplicata?.name === row.name}
                  >
                    {isPreparingDuplicata && selectedRowDuplicata?.name === row.name ? (
                      <>
                        Préparation <CircularProgress size={16} style={{ marginLeft: "8px" }} />
                      </>
                    ) : (
                      "Préparer Duplicata"
                    )}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Print Components */}
      {selectedRowFacture && invoiceFacture && (
        <div className={classes.print}>
          <TicketTotal
            details={invoiceFacture}
            company={company}
            ref={componentRefTicketTotal}
          />
        </div>
      )}
      {selectedRowDuplicata && invoiceDuplicata && (
        <div className={classes.print}>
          <TicketTotalDuplicata
            details={invoiceDuplicata}
            company={company}
            ref={componentRefTicketDuplicata}
          />
        </div>
      )}
    </Box>
  );
};