import React from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { calculatePrices } from "../../../../../helpers/utils";

export const FreeItemTableBody = ({
  products,
  classes,
  currencySymbol,
  ignorePricingRule,
  itemPrestationList,
  isPricingRule,
}) => {
  const { t } = useTranslation(["payement", "common"]);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);

  const calculateTaxRate = (item) => {
    const matchingItem = itemPrestationList.find(prestationItem => prestationItem.item_code === item.item_code);
    if (matchingItem) {
      const standardRate = parseFloat(matchingItem.standard_rate);
      const prixDeVenteHT = parseFloat(matchingItem.prix_de_vente_ht);
      if (standardRate && prixDeVenteHT) {
        return ((prixDeVenteHT - standardRate) / standardRate) * 100;
      }
    }
    return 0;
  };

   const tvaTaxes = (product) => {
    const prestationTaxRate = calculateTaxRate(product);
    let taxRate;
    let taxAmount;

    if (prestationTaxRate > 0) {
      taxRate = prestationTaxRate;
      taxAmount = (product.net_amount * taxRate) / 100;
    } else {
      taxRate = parseFloat(product.item_tax_template?.match(/\d+/g)?.toString().replace(",", ".")) || 0;
      taxAmount = (product.net_amount * taxRate) / 100;
    }

    const formattedTaxRate = `${taxRate.toFixed(2)}%`;
    const formattedTaxAmount = taxAmount.toFixed(3);

    return `${formattedTaxRate}: ${formattedTaxAmount} ${currencySymbol}`;
  };

  const getItemPrices = (product) => {
    const prices = calculatePrices({
      products: [product],
      itemDetails: itemDetails.filter(item => item.item_code === product.item_code),
      ignorePricingRule,
      discount: 0,
      taxsalesvalue: 0,
      itemPrestationList
    });

    const quantity = parseFloat(product.qty || product.quantity || 1);
    return {
      unitPrice: parseFloat((prices.subtotal / quantity).toFixed(3)),
      totalPrice: parseFloat((prices.total - 0.1).toFixed(3)),
      discountAmount: prices.discountAmount,
      taxAmount: prices.taxTotal
    };
  };

  const prixTTC = (product) => {
    const prices = getItemPrices(product);
    return prices.unitPrice;
  };

  const prixTTCUnit = (product) => {
    const quantity = parseFloat(product.qty || product.quantity || 1);
    const total = getTotalTTC(product);
    return total / quantity;
  };

   const discountPercentage = (element) => {
    return (element.discount_amount * 100) / element.price_list_rate;
  };


  const discountAmount = (product) => {
    const prices = getItemPrices(product);
    return prices.discountAmount;
  };

  const getTotalTTC = (product) => {
    const prices = getItemPrices(product);
    return prices.totalPrice;
  };

  const processPricingRules = (pricingRules) => {
    if (typeof pricingRules === "string") {
      try {
        const parsedValue = JSON.parse(pricingRules);
        return Array.isArray(parsedValue) ? parsedValue : "";
      } catch (error) {
        return pricingRules;
      }
    }
    return "";
  };

  return (
    <>
      {products?.map((product) => (
        <TableRow key={product?.item_name} className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <Box fontWeight="bold" fontSize="15px" fontFamily="Verdana" color="#000000">
              {product?.quantity || product?.qty}X <br/>
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              fontWeight={"bold"}
              fontSize={"15px"}
              fontFamily={"Verdana"}
               color={"#000000"}
            >
               
              <Box   
              fontSize={"9px"}
              fontFamily={"Verdana"}
               color={"#000000"} > {product?.item_code}{" "}</Box>
               
            </Box>
            {/* <Box fontFamily={"Verdana"} fontSize={"12px"} color={"#000000"}>
              P.U: {prixTTC(product)?.toFixed(3)} {currencySymbol}
            </Box> */}
            {/* {product.discount ? (
              <Box
                fontSize={"12px"}
                fontFamily={"Verdana"}
                 color={"#000000"}
              >
                {t("common:remise")}: {product.discount}% (
                {discountAmount(product)?.toFixed(3)} {currencySymbol})
              </Box>
            ) : null} */}

            {/* {!ignorePricingRule && product.discount_amount ? (
              <Box
                fontSize={"12px"}
                fontFamily={"Verdana"}
                 color={"#000000"}
              >
                {t("common:remise")}: {discountPercentage(product).toFixed(3)}%
                ( {product.discount_amount.toFixed(3)} {currencySymbol})
              </Box>
            ) : null} */}
            {/* <Box
              fontSize={"12px"}
              fontFamily={"Verdana"}
               color={"#000000"}
            >
              {tvaTaxes(product)}
            </Box> */}
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
            width={100}
              fontWeight={"bold"}
              fontSize={"8px"}
              fontFamily={"Verdana"}
               color={"#000000"}
            >
              {product?.item_name}{" "} <br/>
            
              {isPricingRule
                ? "(" + (processPricingRules(product?.pricing_rules) || "") + ")"
                : null}
            </Box>
            <Box fontFamily={"Verdana"} fontSize={"9px"} color={"#000000"}>
              P.U: {prixTTCUnit(product)?.toFixed(2)} {currencySymbol}
            </Box>
            {product.discount ? (
              <Box
                fontSize={"9px"}
                fontFamily={"Verdana"}
                 color={"#000000"}
              >
                {t("common:remise")}: {product.discount}% (
                  {prixTTCUnit(product)?.toFixed(2)} {currencySymbol})
              </Box>
            ) : null}

            {!ignorePricingRule && product.discount_amount ? (
              <Box
                fontSize={"9px"}
                fontFamily={"Verdana"}
                 color={"#000000"}
              >
                {t("common:remise")}: {discountPercentage(product).toFixed(3)}%
                ( {product.discount_amount.toFixed(3)} {currencySymbol})
              </Box>
            ) : null}
            <Box
              fontSize={"9px"}
              fontFamily={"Verdana"}
               color={"#000000"}
            >
               
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box align="right" bottom="0" fontFamily="Verdana" color="#000000">
              {getTotalTTC(product).toFixed(2)} {currencySymbol}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};