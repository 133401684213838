import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  main: {
    fontFamily: "system-ui",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    maxWidth: "800px",
    margin: "auto",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  header: {
    paddingBottom: "20px",
    textAlign: "center",
  },
  logo: {
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    marginBottom: "20px",
  },
  company: {
    textAlign: "right",
    fontSize: "12px",
    lineHeight: 1.6,
  },
  project: {
    marginBottom: "20px",
    fontSize: "12px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  tableheader: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
    textAlign: "center",
    padding: "8px",
    border: "1px solid #ddd",
  },
  tableColumn: {
    textAlign: "center",
    padding: "8px",
    border: "1px solid #ddd",
  },
  footer: {
    marginTop: "20px",
    textAlign: "center",
    fontSize: "12px",
  },
  barcode: {
    marginTop: "20px",
    textAlign: "center",
  },
}));

export const TicketTotal = React.forwardRef(({ details, company }, ref) => {
  
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const activeImageTheme = useSelector((state) => state.Theme.image);

  return (
    <div className={classes.main} ref={ref}>
      {/* Header Section */}
      <header className={classes.header}>
        <div className={classes.logo}>
          <img
            src={activeImageTheme}
            alt="Company Logo"
            style={{ maxWidth: "120px" }}
          />
        </div>
        <h1 className={classes.title}>{details.name || "Facture"}</h1>
          </header>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
  {/* Company Information */}
  <div className={classes.company} >
    <div>
      <strong>{company?.name}</strong>
    </div>
     
    <div>{company?.phone_no && `Tel: ${company?.phone_no}`}</div>
    <div>{company?.email && `Email: ${company?.email}`}</div>
  </div>

  {/* Project Details */}
  <div className={classes.project}  >
    <div>
      <strong>{t("checkout:customer")}</strong>:{" "}
      {details?.customer_name || "N/A"}
    </div>
    <div>
      <strong>{t("common:email")}</strong>:{" "}
      {details?.contact_email || "N/A"}
    </div>
    <div>
      <strong>{t("common:date")}</strong>:{" "}
      {details?.posting_date || "N/A"}
    </div>
  </div>
</div>


      {/* Items Table */}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("common:ref")}</th>
            <th className={classes.tableheader}>{t("common:produit")}</th>
            <th className={classes.tableheader}>{t("common:Description")}</th>
            <th className={classes.tableheader}>P.U</th>
            <th className={classes.tableheader}>{t("common:remise")} (%)</th>
            <th className={classes.tableheader}>{t("common:qty")}</th>
            <th className={classes.tableheader}>{t("common:total")}</th>
          </tr>
        </thead>
        <tbody>
          {details.items?.map((item, index) => (
            <tr key={index}>
              <td className={classes.tableColumn}>{item.item_code}</td>
              <td className={classes.tableColumn}>{item.item_name}</td>
              <td className={classes.tableColumn}>{item.description}</td>
              <td className={classes.tableColumn}>
                {item.rate.toFixed(3)} {details.currency}
              </td>
              <td className={classes.tableColumn}>
                {item.discount_percentage.toFixed(3)}%
              </td>
              <td className={classes.tableColumn}>{item.qty}</td>
              <td className={classes.tableColumn}>
                {item.amount.toFixed(3)} {details.currency}
              </td>
            </tr>
          ))}
          {/* Totals Section */}
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              Total H.T
            </td>
            <td className={classes.tableColumn}>
              {details.net_total.toFixed(3)} {details.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:totalTaxe")}
            </td>
            <td className={classes.tableColumn}>
              {details.total_taxes_and_charges.toFixed(3)} {details.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              Total TTC
            </td>
            <td className={classes.tableColumn}>
              {details.grand_total.toFixed(3)} {details.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              Total à Payer
            </td>
            <td className={classes.tableColumn}>
              {details.paid_amount.toFixed(3)} {details.currency}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Payments Table */}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("checkout:reglement")}</th>
            <th className={classes.tableheader}>{t("clients:paidAmount")}</th>
            <th className={classes.tableheader}>
              {t("common:montantRendre")}
            </th>
          </tr>
        </thead>
        <tbody>
          {details.payments?.map((payment, index) => (
            <tr key={index}>
              <td className={classes.tableColumn}>
                {payment.mode_of_payment || t("common:noData")}
              </td>
              <td className={classes.tableColumn}>
                {payment.amount.toFixed(3)} {details.currency}
              </td>
              <td className={classes.tableColumn}>0 {details.currency}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Taxes Table */}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("common:tax")}</th>
            <th className={classes.tableheader}>{t("common:taxAmount")}</th>
            <th className={classes.tableheader}>
              {t("common:taxAmountAfterDiscount")}
            </th>
          </tr>
        </thead>
        <tbody>
          {details.taxes?.map((tax, index) => (
            <tr key={index}>
              <td className={classes.tableColumn}>
                {tax.account_head || t("common:noData")}
              </td>
              <td className={classes.tableColumn}>
                {tax.tax_amount.toFixed(3)} {details.currency}
              </td>
              <td className={classes.tableColumn}>
                {tax.tax_amount_after_discount_amount.toFixed(3)}{" "}
                {details.currency}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classes.barcode}>
        <Barcode value={details.name || "N/A"} width={1} height={40} />
      </div>
      {/* Footer */}
      <div className={classes.footer}>
        Merci pour votre confiance !
        <br />
        Veuillez conserver cette facture comme preuve d'achat.
      </div>

      
     
    </div>
  );
});

export default TicketTotal;