import {
  INVENTORY_LOADED,
  GET_STOCK_SUMMARY,
  STOCK_SUMMARY_LOADED,
  STOCK_ENTRY_CREATED,
  CREATE_STOCK_ENTRY,
  STOCK_REPORT_LOADED,
  GET_STOCK_REPORT,
  GET_STOCK_QTY_DATA,
  STOCK_QTY_DATA_LOADED,
  CREATE_STOCK_REC,
  STOCK_REC_CREATED,
  SET_CHECKED_ITEMS,
  CREATE_INV_LIST_SUCCESS,
  CREATE_INV_LIST,
  GET_INV_LISTS,
  INV_LISTS_LOADED,
  GET_INV_LIST_BY_ID,
  INV_LIST_LOADED,
  UPDATE_LIST_STATUS,
  UPDATE_LIST_STATUS_SUCCESS,
  SET_LIST_EDIT,
  UPDATE_SELECTED_PARAMS,
  STOCK_REPORT_DATA_LOADED,
} from "./inventory.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import { SetNotification } from "../notification/notification.action";
import axiosInstance from "../../history/axiosInstance";

export const LoadInventory = (inventory) => {
  return {
    type: INVENTORY_LOADED,
    inventory: inventory,
  };
};

export const UpdateParams = (params) => {
  return {
    type: UPDATE_SELECTED_PARAMS,
    params: params,
  };
};

export const LoadStockSummary = (data) => ({
  type: GET_STOCK_SUMMARY,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/stock/stock-summary`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: STOCK_SUMMARY_LOADED,
        meta: "",
      },
    },
  },
});

export const createStckEntry = (content) => ({
  type: CREATE_STOCK_ENTRY,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/stock/create-stock-entry`,
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: STOCK_ENTRY_CREATED,
        meta: {
          notification: {
            code: "success",
            message: "notification:stockEntryCreated",
          },
        },
      },
      rollback: { type: FAILED_ADD_DATA },
    },
  },
});

export const LoadStockAnalyticsReport = (data) => ({
  type: GET_STOCK_REPORT,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/stock/analytics-report`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: STOCK_REPORT_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadStockDataReport = (data) => ({
  type: GET_STOCK_QTY_DATA,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/stock/projected-qty`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: STOCK_QTY_DATA_LOADED,
        meta: "",
      },
    },
  },
});

export const SetStockReportData = (data) => {
  return {
    type: STOCK_REPORT_DATA_LOADED,
    payload: data,
  };
};

export const UploadStockDataReport = (data) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/save-inventory-report`,
        data,
        header
      )
      .then(({ data }) => {
        window.location.assign(data);
        dispatch(
          SetNotification({
            code: "success",
            message: "notification:invListSaved",
          })
        );
      })
      .catch(function (error) {
        dispatch(
          SetNotification({
            code: "error",
            message: "notification:invListSavedErr",
          })
        );
      });
  };
};

export const CreateStockReconcialiation = (data) => ({
  type: CREATE_STOCK_REC,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/stock/create-stock-reconciliation`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: STOCK_REC_CREATED,
        meta: {
          notification: {
            code: "success",
            message: "notification:reconciliationCreated",
          },
          then: (data) => (dispatch, getState) => {
            window.location.reload();
          },
        },
      },
      rollback: { type: FAILED_ADD_DATA },
    },
  },
});

export const SetCheckedItems = (data) => {
  return {
    type: SET_CHECKED_ITEMS,
    checkedItems: data,
  };
};

export const CreateInvList = (content) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/save-inventory-list`,
        JSON.stringify({ content }),
        header
      )
      .then(({ data }) => {
        if (data.list_id) {
          dispatch(
            SetNotification({
              code: "success",
              message: "notification:invListAdded",
            })
          );
          window.location.href = `/inventory/${content.list_id}`;
        } else {
          if (Array.isArray(data)) {
            dispatch(
              SetNotification({
                code: "error",
                message:
                  data.length === 1
                    ? "notification:invProductPending"
                    : "notification:invProductsPending",
                values: `${data?.map((p) => " " + p)}`,
              })
            );
          } else {
            let parseArray = Object.values(data);
            dispatch(
              SetNotification({
                code: "error",
                message:
                  parseArray?.length == 1
                    ? "notification:invProductPending"
                    : "notification:invProductsPending",
                values: `${parseArray?.map((p) => " " + p)}`,
              })
            );
          }
        }
      });
  };
};
export const DeleteInvList = (id) => {
  return (dispatch) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    };

    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/inventory-lists/delete/${id}`,
        null, // No body needed, pass `null`
        { headers } // Pass headers as the third argument
      )
      .then(({ data }) => {
        if (data.status === "success") {
          // Notify user
          dispatch(
            SetNotification({
              code: "success",
              message: "notification:invListDeleted",
            })
          );

          // Reload inventory list
          dispatch(loadInventoryLists());
        }
      })
      .catch((error) => {
        console.error("Error deleting inventory list:", error);
        dispatch(
          SetNotification({
            code: "error",
            message: "notification:invListDeleteFailed", // Update to reflect failure
          })
        );
      });
  };
};
export const loadInventoryLists = (company) => ({
  type: GET_INV_LISTS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/inventory-lists`,
        method: "POST",
        body: JSON.stringify({
          company: `${company}`,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: INV_LISTS_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadInvListById = (id) => ({
  type: GET_INV_LIST_BY_ID,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/inventory-lists/details`,
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: INV_LIST_LOADED,
        meta: "",
      },
    },
  },
});

export const UpdateListStatus = (content) => ({
  type: UPDATE_LIST_STATUS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/inventory-lists/update`,
        method: "POST",
        body: JSON.stringify({
          id: content.list_id,
          status: content.status,
          products: JSON.stringify(content.products),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_LIST_STATUS_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:statusChanged",
          },
        },
      },
    },
  },
});

export const UpdateList = (content) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/inventory-lists/update-list`,
        JSON.stringify(content),
        header
      )
      .then(({ data }) => {
        if (data.list_id) {
          dispatch(
            SetNotification({
              code: "success",
              message: "notification:invListModified",
            })
          );
          window.location.href = `/inventory/${content.list_id}`;
        } else {
          if (Array.isArray(data)) {
            dispatch(
              SetNotification({
                code: "error",
                message:
                  data.length == 1
                    ? "notification:invProductPending"
                    : "notification:invProductsPending",
                values: `${data?.map((p) => " " + p)}`,
              })
            );
          } else {
            let parseArray = Object.values(data);
            dispatch(
              SetNotification({
                code: "error",
                message:
                  data.length == 1
                    ? "notification:invProductPending"
                    : "notification:invProductsPending",
                values: `${parseArray?.map((p) => " " + p)}`,
              })
            );
          }
        }
      });
  };
};

export const SetListToEdit = (data) => {
  return {
    type: SET_LIST_EDIT,
    list: data,
  };
};
