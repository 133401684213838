import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStylesTicket = makeStyles((theme) => ({
  receiptContainer: {
    fontFamily: "Verdana, sans-serif",
    width: 400,
   
    left: "25%",
     fontWeight: "bold",
     backgroundColor: "#ffffff",
     color: "#000000",
     padding: "30px",
     borderRadius: "10px",
     boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
     lineHeight: 1.6,
     textAlign: "center",
     position: "relative", // To position the background text
     overflow: "hidden",
  },
  duplicata: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "60px",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.1)", // Light color for background text
    textTransform: "uppercase",
    pointerEvents: "none", // Ensures it does not interfere with the receipt content
    zIndex: 0, // Place it behind the receipt content
    whiteSpace: "nowrap",
  },
  content: {
    position: "relative", // Content sits above the background text
    zIndex: 1,
  },
  header: {
    marginBottom: "20px",
  },
  logo: {
    width: "80px",
    margin: "0 auto 10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "10px 0",
  },
  subtitle: {
    fontSize: "14px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    marginBottom: "20px",
  },
  tableHeader: {
    borderBottom: "2px solid #000",
    padding: "8px",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "left",
  },
  tableCell: {
    padding: "8px",
    fontSize: "12px",
    borderBottom: "1px solid #ddd",
    textAlign: "left",
  },
  totals: {
    textAlign: "right",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "10px",
    marginRight: "50px",
  },
  footer: {
    marginTop: "30px",
    fontSize: "12px",
  },
  barcode: {
    marginTop: "20px",
  },
}));

const TicketTotalDuplicata = React.forwardRef(({ details, company }, ref) => {
 
  const classes = useStylesTicket();
  const activeImageTheme = useSelector((state) => state.Theme.image);

  const [taxList, setTaxList] = useState([]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };
  const { t } = useTranslation(["common"]);
  const getTaxList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Account",
        fields: ["*"],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) setTaxList(data);
      });
  };

  useEffect(() => {
    getTaxList();
  }, []);

  return (
    <div className={classes.receiptContainer} ref={ref}>
      <div className={classes.duplicata}>Duplicata</div>
      <div className={classes.content}>
        {/* Header Section */}
        <div className={classes.header}>
          <div className={classes.logo}>
            <img
              src={activeImageTheme}
              style={{
                display: "block",
                margin: "0 auto",
                width: "100px",
              }}
              alt="Logo"
            />
          </div>
          <div className={classes.title}>{company?.name || "CSS Boutique"}</div>
          <div className={classes.subtitle}>{company?.website || ""}</div>
          <div className={classes.subtitle}>{`Tel: ${company?.phone_no}`}</div>
          <div className={classes.subtitle}>{`Date: ${
            details?.posting_date
          }`}</div>
          <div className={classes.subtitle}>{`Document Reference: ${
            details?.name
          }`}</div>
          <div className={classes.subtitle}>{`Caisse: ${
            details?.pos_profile
          }`}</div>
          <div className={classes.subtitle}>{`Vendeur: ${
            details?.aggregated_sales_team
          }`}</div>
          <div className={classes.subtitle}>{`Customer: ${
            details?.customer_name
          }`}</div>
        </div>

        {/* Items Table */}
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.tableHeader}>Qte</th>
              <th className={classes.tableHeader}>Description</th>
              <th className={classes.tableHeader}>Amount HT</th>
              <th className={classes.tableHeader}>Amount TTC</th>
              
            </tr>
          </thead>
          <tbody>
            {details?.items?.map((item, index) => (
              <tr key={index}>
                <td className={classes.tableCell}>{item?.qty} X</td>
                <td className={classes.tableCell}>{item?.description || "N/A"}</td>
                <td className={classes.tableCell}>
                  {item?.rate?.toFixed(3) || "0.000"} TND
                </td>
                <td className={classes.tableCell}>
                  {(item?.amount + 0.1)?.toFixed(3) || "0.000"} TND
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>

        {/* Payment Table */}
        <table className={classes.table}>
          <tbody>
            {details?.payments?.map((payment, index) => (
              <tr key={index}>
                <td className={classes.tableCell}>{payment?.mode_of_payment}</td>
                <td className={classes.tableCell}>
                  {payment?.amount?.toFixed(3) || "0.000"} TND
                </td>
              </tr>
            ))}
            <tr>
            <td className={classes.tableCell}>{t("common:montantRendre")}</td>
            <td className={classes.tableCell}>
              {/* {details?.change_amount?.toFixed(1) || "0.000"} TND */}
              {"0.000"} TND
            </td>
          </tr>
          </tbody>
        </table>

        {/* Totals Section */}
        <div className={classes.totals}>
        <div>{`Timbre Fiscale: 0.1 TND`}</div>
          <div>{`Amount H.T: ${details?.net_total?.toFixed(3) || "0.000"} TND`}</div>
          <div>{`T.V.A: ${
            details?.total_taxes_and_charges?.toFixed(3) || "0.000"
          } TND`}</div>
          <div>{`Total: ${(details?.grand_total + 0.1)?.toFixed(3) || "0.000"} TND`}</div>
        </div>

        {/* Footer Section */}
        <div className={classes.footer}>
          <div>
            L'échange est valable uniquement pendant 15 jours et en dehors des
            périodes de solde.
          </div>
          <div>Thank you for your visit!</div>
          <div className={classes.barcode}>
            <Barcode
              value={details?.name || "ACC-PSINV-2024-01661"}
              background="transparent"
              fontSize={13}
              width={1}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default TicketTotalDuplicata;