import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
 import BarChartIcon from "@material-ui/icons/BarChart";
import { useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import RemoveIcon from "@material-ui/icons/Remove";
import { SetNotification } from "../../../store/notification/notification.action";
import axiosInstance from "../../../history/axiosInstance";
import { useTableStyles } from "../../../styles/table.style";
import { formatDate } from "../../../helpers/utils";
import { Header } from "../../stockEntry/components/header";

function CreateStockEntryReceipt() {
      const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    
    const company =   globalDefaults?.default_company;
    
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "buying", "checkout"]);
  const stores = useSelector((state) => state.Login.stores);
  const stockQtyReport = useSelector((state) => state.Inventory.stockQtyReport);
  const items = useSelector((state) => state.Product.products);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [item, setItem] = useState("");

  const handleClick = (event, itemCode) => {
    setAnchorEl(event.currentTarget);
    setItem(itemCode);
    const filtered = stockQtyReport?.filter(
      (item) => item?.item_code === itemCode
    );
    setFilteredItems(filtered);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openInputs, setOpenInputs] = useState(false);

  const [reqState, setReqState] = useState({
    doctype: "Material Request",
    material_request_type: "Material Transfer",
    company: company,
    transaction_date: formatDate(new Date(), "YYYY-MM-DD"),
    status: "",
    transfer_status: "",
    items: [],
    set_warehouse: "",
    set_from_warehouse: "",
    schedule_date: null,
  });

  const [itemState, setItemState] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null); // State to hold the selected item

  async function getItemDetails(itemCode) {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URI}/api/item/item-details`,
        {
          args: {
            item_code: itemCode,
            company: company,
            doctype: "Material Request",
          },
          overwrite_warehouse: true,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
      
      if (response.data?.message) {
        const item = {
          doctype: "Material Request Item",
          item_group: response.data.message?.item_group,
          stock_uom: response.data.message?.stock_uom,
          parentfield: "items",
          parenttype: "Material Request",
          schedule_date: reqState.schedule_date
            ? reqState.schedule_date
            : formatDate(new Date(), "YYYY-MM-DD"),
          item_code: response.data.message?.item_code,
          uom: response.data.message?.uom,
          item_name: response.data.message?.item_name,
          description: response.data.message?.description,
          warehouse:
            reqState.set_warehouse !== ""
              ? reqState.set_warehouse
              : response.data.message?.warehouse,
          income_account: response.data.message?.income_account,
          expense_account: response.data.message?.expense_account,
          cost_center: response.data.message?.cost_center,
          external_id: response.data.message?.item_code.split('-')[1],
          qty: 1,
          stock_qty: 1,
          conversion_factor: 1,
          from_warehouse:
            reqState.set_from_warehouse !== "" ? reqState.set_from_warehouse : "",
        };
        setItemState(item);
        setSelectedItem(item); // Set the found item as the selected item
      }
    } catch (error) {
      console.error("Error fetching item details:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleItemInsert = (item) => {
    if (item) {
      getItemDetails(item.item_code);
      setSelectedItem(item); // Update selected item state
    }
  };

  const handleItemChange = (e) => {
    setItemState({ ...itemState, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, event) => {
    if (!isNaN(Date.parse(date))) {
      setItemState({
        ...itemState,
        schedule_date: date.toISOString().slice(0, 10),
      });
    } else {
      setItemState({
        ...itemState,
        schedule_date: "",
      });
    }
  };

  const handleDateChangement = (date, event) => {
    if (!isNaN(Date.parse(date))) {
      if (reqState.items.length !== 0) {
        let items = [...reqState.items];
        items.forEach((item) => {
          item.schedule_date = date.toISOString().slice(0, 10);
        });
        setReqState({
          ...reqState,
          items: items,
          schedule_date: date.toISOString().slice(0, 10),
        });
      } else {
        setReqState({
          ...reqState,
          schedule_date: date.toISOString().slice(0, 10),
        });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "set_from_warehouse") {
      if (reqState.items.length !== 0) {
        let items = [...reqState.items];
        items.forEach((item) => {
          item.from_warehouse = e.target.value;
        });
        setReqState({
          ...reqState,
          [e.target.name]: e.target.value,
          items: items,
        });
      } else {
        setReqState({ ...reqState, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === "set_warehouse") {
      if (reqState.items.length !== 0) {
        let items = [...reqState.items];
        items.forEach((item) => {
          item.warehouse = e.target.value;
        });
        setReqState({
          ...reqState,
          [e.target.name]: e.target.value,
          items: items,
        });
      } else {
        setReqState({ ...reqState, [e.target.name]: e.target.value });
      }
    }
  };

  const handleInsert = () => {
    setReqState({ ...reqState, items: [...reqState.items, itemState] });
    setItemState(null);
    setItem("");
    setOpenInputs(false);
  };

  const handleRemoveRow = (idx) => {
    let newItems = [...reqState.items];
    newItems.splice(idx, 1);
    setReqState({ ...reqState, items: newItems });
  };

  function groupByWarehouseAndFromWarehouse(originalObject) {
    return originalObject?.items?.reduce((acc, item) => {
      const key = `${item.from_warehouse}-${item.warehouse}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }

  function createNewObjects(originalObject, groupedItems) {
    return Object.keys(groupedItems)?.map((key) => {
      const newObject = { ...originalObject };
      newObject.schedule_date = groupedItems[key]?.[0]?.schedule_date;
      
      newObject.set_warehouse = groupedItems[key]?.[0]?.warehouse;
      newObject.items = groupedItems[key];
      return newObject;
    });
  }

  function splitItemsByWarehouseAndFromWarehouse(originalObject) {
    const groupedItems = groupByWarehouseAndFromWarehouse(originalObject);
    return createNewObjects(originalObject, groupedItems);
  }

const handleSubmit = async () => {
  setLoadingSubmit(true);

  try {
    // Split items by warehouse (ensure this is valid)
    const itemsByWarehouse = splitItemsByWarehouseAndFromWarehouse(reqState) || [];
    
    // Map requests to API payloads
    const requests = itemsByWarehouse.map((item) => {
         console.log('itemitem',item)
      const payload = {
        action: "Submit",
        doc: {
          doctype: "Stock Entry",
          purpose: "Material Receipt",
          stock_entry_type: "Material Receipt",
          posting_date: new Date().toISOString().split("T")[0],
          posting_time: new Date().toLocaleTimeString(),
          company: company,
          items:item.items.map((item) => ({
             
                item_code: item.item_code, // Ensure this field exists
                qty: item.qty || 0, // Default to 0 if missing
                s_warehouse: item.from_warehouse || "",
                t_warehouse: item.warehouse || "",
                description: item.description || "",
                uom: "Nos", // Assuming default UOM
            
          })),
          to_warehouse: item.warehouse || "",
        },
      };

  
      return axiosInstance.post(
        `${process.env.REACT_APP_API_URI}/api/stock-entry/create`,
        payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": process.env.REACT_APP_API_KEY,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
    });

    // Execute all requests
    await Promise.all(requests);

    // Success feedback
    setLoadingSubmit(false);
    dispatch(
      SetNotification({
        code: "success",
        message: "notification:receiptStockCreated",
      })
    );
    history.push("/stock-receipt");
  } catch (error) {
    console.error("Error while submitting stock entry:", error);
    setLoadingSubmit(false);
    dispatch(
      SetNotification({
        code: "error",
        message: "Error creating stock entry. Please try again.",
      })
    );
  }
};


  function isInsertDisabled() {
    if (!itemState) return true;
    if (!itemState?.qty) return true;
    if (!itemState?.schedule_date) return true;
    if (!itemState?.warehouse) return true;
    return false;
  }

  function isSaveDisabled() {
    const allItemsHaveWarehouses = reqState?.items?.every(
      (item) => item?.warehouse
    );
    if (
      !allItemsHaveWarehouses &&
      (!reqState.set_warehouse || !reqState.set_from_warehouse)
    )
      return true;
    if (loadingSubmit) return true;
    if (reqState?.items?.length === 0) return true;
    return false;
  }

  return (
    <div>
        <Header/>
      <div
        style={{
          padding: "20px 0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 10,
        }}
      >
        {/* <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
          <FormControl variant="outlined">
            <KeyboardDatePicker
              size="small"
              label={t("buying:RequiredOn")}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              name="schedule_date"
              inputVariant="outlined"
              id="date-picker-inline"
              value={reqState.schedule_date}
              onChange={handleDateChangement}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              minDate={formatDate(new Date(), "YYYY-MM-DD")}
              minDateMessage={t("common:minDateMessage")}
            />
          </FormControl>
        </MuiPickersUtilsProvider> */}
        {/* <FormControl variant="outlined" size="small">
          <InputLabel>{t("common:mgsSourceAll")}</InputLabel>
          <Select
            label={t("common:mgsSourceAll")}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={reqState.set_from_warehouse}
            IconComponent={ExpandMore}
            onChange={handleChange}
            name="set_from_warehouse"
            endAdornment={
              reqState.set_from_warehouse !== "" ? (
                <div style={{ marginRight: 20 }}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setReqState({ ...reqState, set_from_warehouse: "" })
                    }
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                </div>
              ) : null
            }
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Array.isArray(stores) &&
              stores
                .filter((el) => el?.name !== reqState.set_warehouse)
                .map((row) => (
                  <MenuItem value={row.name} key={row.name}>
                    {row.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl> */}
        {/* <FormControl variant="outlined" size="small">
          <InputLabel>{t("common:mgsCibleAll")}</InputLabel>
          <Select
            label={t("common:mgsCibleAll")}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={reqState.set_warehouse}
            IconComponent={ExpandMore}
            onChange={handleChange}
            name="set_warehouse"
            endAdornment={
              reqState.set_warehouse !== "" ? (
                <div style={{ marginRight: 20 }}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setReqState({ ...reqState, set_warehouse: "" })
                    }
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                </div>
              ) : null
            }
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Array.isArray(stores) &&
              stores
                .filter((el) => el?.name !== reqState.set_from_warehouse)
                .map((row) => (
                  <MenuItem value={row.name} key={row.name}>
                    {row.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl> */}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        <Button
          onClick={() => setOpenInputs(!openInputs)}
          color="primary"
          variant="text"
          size="small"
        >
          <AddIcon />
        </Button>
      </div>

      {/* items table */}
      <div className={tableClasses.paper}>
        <Table className={tableClasses.table}>
          <TableHead>
            <TableCell className={tableClasses.tablecellHeader}>
              {t("common:itemCode")} <span style={{ color: "red" }}>*</span>
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader}>
              {t("common:schedule_date")}{" "}
              <span style={{ color: "red" }}>*</span>
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} width={120}>
              {t("common:qty")} <span style={{ color: "red" }}>*</span>
            </TableCell>
            
            <TableCell className={tableClasses.tablecellHeader}>
              {t("common:mgsCible")} <span style={{ color: "red" }}>*</span>
            </TableCell>
            <TableCell
              className={tableClasses.tablecellHeader}
              width={50}
            ></TableCell>
            <TableCell
              className={tableClasses.tablecellHeader}
              width={50}
            ></TableCell>
          </TableHead>
          <TableBody>
            {reqState?.items?.map((item, idx) => (
              <TableRow className={tableClasses.tableRow} key={idx}>
                <TableCell className={tableClasses.tableCell}>
                  <span
                    onClick={(e) => handleClick(e, item.item_code)}
                    style={{
                      fontWeight: 700,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    aria-describedby={id}
                  >
                    {item.item_code}
                  </span>
                </TableCell>
                <TableCell className={tableClasses.tableCell}>
                  {item.schedule_date}
                </TableCell>
                <TableCell className={tableClasses.tableCell} width={120}>
                  {item.qty}
                </TableCell>
                <TableCell className={tableClasses.tableCell}>
                  {item.warehouse === "" ? "-" : item.warehouse}
                </TableCell>
                <TableCell
                  className={tableClasses.tableCell}
                  width={50}
                ></TableCell>
                <TableCell
                  className={tableClasses.tableCell}
                  width={50}
                  align="right"
                >
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveRow(idx)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {openInputs && (
              <TableRow className={tableClasses.tableRow}>
                <TableCell className={tableClasses.tableCell}>
                <Autocomplete 
                  size="small"
                  style={{ width: "100%" }}
                  id="combo-box-demo"
                  value={selectedItem} // Controlled component with selectedItem state
                  onChange={(event, newValue) => {
                    setSelectedItem(newValue); // Update selected item when user selects from dropdown
                    handleItemInsert(newValue);
                  }}
                  options={items}
                  getOptionLabel={(option) => option.item_code}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue) {
                      // Find item by barcode
                      const foundItem = items.find((item) => item.barcode === newInputValue);
                      
                      if (foundItem) {
                        // If found, set as selected item and fetch details
                        setSelectedItem(foundItem);
                        getItemDetails(foundItem.item_code);
                      } else {
                        setItemState(null); // Clear state if no match
                      }
                    } else {
                      setSelectedItem(null); // Clear selected item if input is cleared
                      setItemState(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {itemState ? (
                              <Tooltip title={t("common:stcklvl")}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => handleClick(e, itemState.item_code)}
                                  aria-describedby={id}
                                >
                                  <BarChartIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {loading ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                </TableCell>
                <TableCell className={tableClasses.tableCell}>
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      size="small"
                      required
                      error={!itemState?.schedule_date}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      name="schedule_date"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      value={itemState ? itemState?.schedule_date : null}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      invalidDateMessage=""
                      minDate={formatDate(new Date(), "YYYY-MM-DD")}
                      minDateMessage={t("common:minDateMessage")}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell className={tableClasses.tableCell} width={120}>
                  <TextField
                    value={itemState?.qty ?? 0}
                    error={!itemState?.qty}
                    onChange={handleItemChange}
                    name="qty"
                    size="small"
                    type="number"
                    variant="outlined"
                    InputProps={
                      itemState?.item_code && {
                        startAdornment: (
                          <>
                            <IconButton
                              style={{ marginLeft: -10 }}
                              onClick={() =>
                                itemState?.qty > 1 &&
                                setItemState({
                                  ...itemState,
                                  qty: itemState?.qty - 1,
                                  stock_qty: itemState?.stock_qty - 1,
                                })
                              }
                              size="small"
                            >
                              <RemoveIcon />
                            </IconButton>
                          </>
                        ),
                        endAdornment: (
                          <>
                            <IconButton
                              style={{ marginRight: -10 }}
                              onClick={() =>
                                itemState?.qty >= 1 &&
                                setItemState({
                                  ...itemState,
                                  qty: itemState?.qty + 1,
                                  stock_qty: itemState?.stock_qty + 1,
                                })
                              }
                              size="small"
                            >
                              <AddIcon />
                            </IconButton>
                          </>
                        ),
                      }
                    }
                  />
                </TableCell>
                {/* <TableCell className={tableClasses.tableCell}>
                  <FormControl variant="outlined" size="small">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={itemState?.from_warehouse ?? ""}
                      error={
                        !itemState?.from_warehouse ||
                        itemState?.warehouse === itemState?.from_warehouse
                      }
                      IconComponent={ExpandMore}
                      onChange={handleItemChange}
                      name="from_warehouse"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {Array.isArray(stores) &&
                        stores.map((row, index) => (
                          <MenuItem value={row.name} key={row.name}>
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </TableCell> */}
                <TableCell className={tableClasses.tableCell}>
                  <FormControl variant="outlined" size="small">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={itemState?.warehouse ?? ""}
                      error={
                        !itemState?.warehouse ||
                        itemState?.warehouse === itemState?.from_warehouse
                      }
                      IconComponent={ExpandMore}
                      onChange={handleItemChange}
                      name="warehouse"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {Array.isArray(stores) &&
                        stores.map((row, index) => (
                          <MenuItem value={row.name} key={row.name}>
                            {row.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell
                  className={tableClasses.tableCell}
                  width={50}
                ></TableCell>
                <TableCell
                  className={tableClasses.tableCell}
                  width={50}
                  align="right"
                >
                  <Tooltip title="Insert">
                    <IconButton
                      disabled={isInsertDisabled()}
                      onClick={handleInsert}
                    >
                      <DoneIcon
                        color={isInsertDisabled() ? "disabled" : "primary"}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Popover
          id={id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Card style={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
                color="primary"
                gutterBottom
              >
                {t("common:stcklvl")} <BarChartIcon color="primary" />
              </Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                component="div"
              >
                {t("common:itemCode")}: {item}
              </Typography>
              {filteredItems?.map((el, i) => (
                <Typography
                  variant="body2"
                  key={i}
                  style={{ paddingTop: "10px" }}
                >
                  <strong>{el.warehouse}</strong>
                  <Divider style={{ marginBottom: 4 }} />
                  <li>
                    {t("common:actual_qty")}: <strong>{el?.actual_qty}</strong>
                  </li>
                  <li>
                    {t("common:projected_qty")}:{" "}
                    <strong>{el?.projected_qty}</strong>
                  </li>
                  <li>
                    {t("common:reservedqty")}:{" "}
                    <strong>{el?.reserved_qty}</strong>
                  </li>
                </Typography>
              ))}
            </CardContent>
          </Card>
        </Popover>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 0px",
        }}
      >
        {splitItemsByWarehouseAndFromWarehouse(reqState)?.length !== 0 ? (
          <Button
            endIcon={
              loadingSubmit ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : (
                <DoneIcon />
              )
            }
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={isSaveDisabled()}
          >
            {`${t("common:save")} ${
              splitItemsByWarehouseAndFromWarehouse(reqState)?.length
            } ${t("common:trqs")}`}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default CreateStockEntryReceipt;