import { GetData } from "../../helpers/request";
import {
  CREATE_STOCK_ENTRY,
  CREATE_STOCK_ENTRY_SUCCESS,
  SET_SELECTED_STOCK_ENTRY,
  STOCK_BALANCE_LOADED,
} from "./stockEntries.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import axiosInstance from "../../history/axiosInstance";

export const getStockEntries = () => {
  const data = {
    doctype: "Stock Entry",
    fields: [
      "`tabStock Entry`.`name`",
      "`tabStock Entry`.`creation`",
      "`tabStock Entry`.`stock_entry_type`",
      "`tabStock Entry`.`purpose`",
      "`tabStock Entry Detail`.`t_warehouse`",
      "`tabStock Entry Detail`.`s_warehouse`",
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/stock-entries`;

  return GetData(url, data);
};
export const getStockEntriesReceipt = (type) => {
  const data = {
    doctype: "Stock Entry",
    fields: [
      "`tabStock Entry`.`name`",
      "`tabStock Entry`.`creation`",
      "`tabStock Entry`.`stock_entry_type`",
      "`tabStock Entry`.`purpose`",
      "`tabStock Entry Detail`.`t_warehouse`",
      "`tabStock Entry Detail`.`s_warehouse`",
    ],
 
    filters: [["Stock Entry","stock_entry_type","=",type]]
  };
  const url = `${process.env.REACT_APP_API_URI}/api/stock-entries`;

  return GetData(url, data);
};
export const getStockEntriesByItem = (item_code) => {
  const data = {
    doctype: "Stock Entry",
    fields: [
      "`tabStock Entry`.`name`",
      "`tabStock Entry`.`creation`",
      "`tabStock Entry`.`stock_entry_type`",
      "`tabStock Entry`.`purpose`",
      "`tabStock Entry Detail`.`t_warehouse`",
      "`tabStock Entry Detail`.`s_warehouse`",
    ],
    filters: [["Stock Entry Detail", "item_code", "=", item_code]],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/stock-entries`;

  return GetData(url, data);
};

export const CreateStockEntry = (content) => {
  const action = {
    type: CREATE_STOCK_ENTRY,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/stock-entry/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_STOCK_ENTRY_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:stockEntryCreated",
            },
            then: (data) => (dispatch, getState) => {
              if (data) window.location.reload();
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadStockEntryById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Stock%20Entry&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(updateSelectedStockEntry(data?.docs[0]));
      });
  };
};

export function updateSelectedStockEntry(stockEntry) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_STOCK_ENTRY,
      payload: stockEntry,
    });
  };
}

export const LoadItemMovementsById = (item_code, date, toDate, company) => {
  const data = {
    report_name: "Stock Ledger",
    filters: {
      company: `${company}`,
      from_date: date,
      to_date: toDate,
      item_code: item_code,
    },
  };
  const url = `${process.env.REACT_APP_API_URI}/api/stock-entries/item-movement`;

  return GetData(url, data);
};

export const loadStockBalance = (data) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(`${process.env.REACT_APP_API_URI}/api/report-query`, data, header)
      .then(({ data }) => {
        dispatch(SetReport(data));
      });
  };
};

export function SetReport(data) {
  return (dispatch, getState) => {
    dispatch({
      type: STOCK_BALANCE_LOADED,
      stockBalance: data,
    });
  };
}

export const PrepareReport = (data) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/stock/prepare-report`,
        data,
        header
      )
      .then((res) => {
        return axiosInstance
          .post(
            `${process.env.REACT_APP_API_URI}/api/get-prepared-report`,
            data,
            header
          )
          .then((ress) => {
            if (ress?.data) {
              setTimeout(() => {
                dispatch(loadStockBalance(data));
              }, 1000);
            }
          });
      });
  };
};