{
  /* eslint-disable */
}
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import EmailIcon from "@material-ui/icons/Email";
import PrintIcon from "@material-ui/icons/Print";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import {
  ClickAwayListener,
  Modal,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  Button,
  OutlinedInput,
  Link,
  Box,
  Dialog,
  DialogActions,
  Grid,
  FormControl,
  IconButton,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Badge,
} from "@material-ui/core";
import { ButtonsFunction } from "../../../../helpers/buttonsFunction";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getRecentOrders,
  manageReturn,
  fetchAvoirList,
  SetTaxSales,
  SetAvoirToPrint,
} from "../../../../store/checkout/checkout.action";
import {
  SendGiftCardEmail,
  loadCouponCodes,
  LoadPricingRuleByName,
} from "../../../../store/client/client.action";
import { GiftCardForm } from "../../../clients/components/carteCadeau/giftCardForm";
import { formatDate } from "../../../../helpers/utils";
import { useTableStyles } from "../../../../theme/table.style";
import { CadeauComponentToPrint } from "../../../../components/componentToPrint/carteCadeau";
import { ReturnForm } from "./returnForm";
import { GlobalfilterData } from "../../../../helpers/helper";
import { isValidEmail, motifNames } from "../../../../helpers/utils";
import { CustomAlert } from "../../../../components/alert";
import AlertModal from "../../../../components/common/alertModal";
import { RecentOrdersTable } from "./recentOrdersTable";
import { Couponcode } from "./couponcode";
import french from "simple-keyboard-layouts/build/layouts/french";
import Keyboard from "react-simple-keyboard";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import axiosInstance from "../../../../history/axiosInstance";
import LoyaltyCard from "./components/loyalty-card";
import { Salesreturnsmanagement } from "./salesReturnsManagement";
import { loadInvoicesReturnedData } from "../../../initialization/apiconfig";
import PricingRules from "../../../../components/pricingRules";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  SetLoyaltyProgramDetails,
  UpdateAppliedLoyCard,
} from "../../../../store/cart/cart.action";

import {
  UpdateProducts,
  UpdatePayment,
  loadItemDetail,
} from "../../../../store/checkout/checkout.action";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    margin: "15% auto",
    padding: "20px",
    width: "20%",
  },
  virtualKeyboard: {
    position: "fixed",
    width: "80%",
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -105%)",
  },
  icon: {
    margin: theme.spacing(1),
    color: theme.palette.freeButton.icon,
  },
  label: {
    textAlign: "left",
    fontSize: 12,
    marginLeft: 5,
    marginBottom: 5,
    marginTop: 5,
  },
  button: {
    width: "100%",
    height: 90,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: "bold",
    border: "3px solid",
    borderColor: theme.palette.blue.main,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
      color: theme.palette.freeButton.background,
      border: "3px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  activatedButton: {
    color: theme.palette.freeButton.background,
    width: "100%",
    height: 90,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.productsTable.button,
    border: "3px solid",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.productsTable.button,
      backgroundColor: theme.palette.secondary.main,
      border: "3px solid",
      borderColor: theme.palette.blue.main,
    },
  },
  buttonvalid: {
    width: "100%",
    height: 90,
    backgroundColor: theme.palette.info.main,
    fontSize: 16,
    fontWeight: "bold",
    border: "3px solid",
    borderColor: theme.palette.blue.main,
    color: theme.palette.freeButton.background,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
      border: "3px solid",
      borderColor: theme.palette.blue.main,
    },
  },
  paper: {
    width: "500px",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  formControl: {
    marginBottom: 5,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  inputForm: {
    height: 40,
  },
  bar: {
    width: 90,
    height: 90,
    cursor: "pointer",
  },
  span: {
    position: "absolute",
    top: "25px",
    left: "25px",
    fontSize: 25,
    color: theme.palette.freeButton.text,
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 5px",
    "& .MuiTableRow-root": {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    marginBottom: "15px",
  },
  tableCell: {
    background: theme.palette.productsTable.tableCell,
    padding: "7px 16px",
    color: theme.palette.primary.main,
  },
  tablecellHeader: {
    paddingBottom: 0,
    color: theme.palette.productsTable.tablecellHeader,
    fontSize: 12,
    borderBottom: "none",
  },
  tableBody: {
    display: "block",
    height: 200,
    overflow: "auto",
  },
  tableRow: {
    height: 25,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  Addbutton: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  icon: {
    marginRight: "0.375rem",
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover": {
      overflow: "visible",
    },
  },
  link: {
    fontSize: 14,
    color: theme.palette.blue.main,
  },
  ticket: {
    "&:after": {
      content: '""',
      display: "block",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      marginTop: "-1.8625rem",
    },
  },
  ticketHeader: {
    marginBottom: "7px",
    textAlign: "center",
    fontWeight: "bold",
  },
  ticketBlock: {
    textAlign: "center",
    boxShadow: "0px 5px 15px 4px #0000000F",
    fontFamily: "Courier Prime",
    color: "#707070",
    fontSize: 14,
    padding: "2rem 0.5rem",
    lineHeight: 2,
    height: 320,
  },
  ticketProducts: {
    display: "flex",
    padding: "10px 38px 10px 28px",
  },
  select: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.productsTable.tablecellHeader,
      color: theme.palette.freeButton.background,
      border: "2px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  headPaper: {
    borderRadius: 10,
    padding: theme.spacing(2),
    background: theme.palette.white.main,
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  badge: {
    backgroundColor: theme.palette.secondary.main,
  },
  activatedButton: {
    color: theme.palette.freeButton.background,
    width: "100%",
    height: 90,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.productsTable.button,
    border: "3px solid",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.productsTable.button,
      backgroundColor: theme.palette.secondary.main,
      border: "3px solid",
      borderColor: theme.palette.blue.main,
    },
  },
  activatedButton: {
    color: theme.palette.freeButton.background,
    width: "100%",
    height: 90,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.productsTable.button,
    border: "3px solid",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.productsTable.button,
      backgroundColor: theme.palette.secondary.main,
      border: "3px solid",
      borderColor: theme.palette.blue.main,
    },
  },
}));

export const Freebutton = (props) => {
   const history = useHistory();
  const suggestion = useSelector((state) => state.Product.products);
  const productsPos = useSelector((state) => state.Product.productsPos);
  const rows = useSelector((state) => state.Checkout.products);
  const currencyExchangeRate = useSelector(
    (state) => state.Checkout.currencyExchangeRate
  );
  const amount = useSelector((state) => state.Checkout.amount);
  const cashier = useSelector((state) => state.Login.selectedData?.cashier);
  const store = useSelector((state) => state.Login.selectedData?.store);
  const selectedClient = useSelector((state) => state.Checkout.client);
  const defaultClient = useSelector((state) => state.Checkout.defaultClient);
  const hold = useSelector((state) => state.Checkout.holds);

  const tableClasses = useTableStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyboard = useRef();
  const talonProps = ButtonsFunction();
  const { SetPercentDiscount } = talonProps;
  const [listTax, setListTax] = useState([]);
  const [listItemPres, setListItemPres] = useState([]);
  const [openTaxModal, setOpenTaxModal] = useState(false);
  const [openPrestationModal, setOpenPrestationModal] = useState(false);
  const [openHoldModal, setOpenHoldModal] = useState(false);
  const [openRecentModal, setOpenRecentModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [openReturnMngModal, setOpenReturnMngModal] = useState(false);
  const [openListCard, setOpenListCard] = useState(false);
  const [returnOperation, setReturnOperation] = useState(false);
  const pricingRules = useSelector((state) => state.Checkout.pricingRules);
  const [alertModal, setAlertModal] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [value, setValue] = useState(null);
  const { t } = useTranslation(["common", "checkout", "clients", "product"]);
  const products = useSelector((state) => state.Checkout.products);
  const recentOrdersList = useSelector((state) => state.Checkout.recentOrders);
  const taxsales = useSelector((state) => state.Checkout.taxsales);
  const [openCouponcode, setOpenCouponcode] = useState(false);
  const cardsList = useSelector((state) => state.Client.giftCardCouponCode);
  const [filteredRecentOrders, setFilteredRecentOrders] = useState([]);
  const [status, setStatus] = useState("Paid");
  const [searchTerm, setSearchTerm] = useState("");

  const [motifs, setMotifs] = useState("");

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const themes_db = useSelector((state) => state.Theme.themes_db);
  const activeTheme = useSelector((state) => state.Theme.activeTheme);

  const [codeCoupon, setCodeCoupon] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);
  const [searchCard, setSearchCard] = React.useState("");
  const [filteredGiftCard, setFilteredGiftCard] = React.useState([]);
  const [openPricingRules, setOpenPricingRules] = React.useState(false);

  const [isOthers, setIsOthers] = React.useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);

  const discounts = useSelector((state) => state.Configuration.discounts);
  const avoirList = useSelector((state) => state.Checkout.avoirList);
  const invoices = useSelector((state) => state.Pos.invoices);

  const returnReasonList = useSelector(
    (state) => state.Configuration.returnReason
  );

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const loyaltyProgramDetails = useSelector(
    (state) => state.Cart.loyaltyProgramDetails
  );

  const openingAmount = parseFloat(
    useSelector((state) => state.Login.cushFund)
  );

  const [posInvoiceList, setPosInvoiceList] = useState([]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getPosInvoiceList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "POS Invoice",
        fields: ['`tabPOS Invoice`.`name`', '`tabPOS Invoice`.`docstatus`'],
        filters: [
          ['POS Invoice', 'docstatus', '=', 0]
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setPosInvoiceList(data);
        }
      });
  };

  const getCashPaymentsBalance = (invoices) => {
    var ttlBalance = 0;

    invoices.length != 0 &&
      invoices.map((inv, i) => {
        inv.payments.map((p, i) => {
          if (p.mode_of_payment == "Cash") {
            ttlBalance += p.amount;
          }
        });
      });

    return ttlBalance;
  };

  const caisseBalance = (
    getCashPaymentsBalance(invoices) + openingAmount
  ).toFixed(3);

  const pricingRuleClient = useSelector(
    (state) => state.Client.pricingRuleClient
  );

  const [openSendCard, setOpenSendCard] = useState({
    open: false,
    row: null,
  });

  const [state, setState] = useState({
    open: false,
    row: null,
  });

  const [action, setAction] = React.useState(null);
  const [keyboardVisibility, setKeyboardVisibility] = React.useState(false);
  const [layout, setLayout] = React.useState("default");
  const [capsLockEnabled, setCapsLockEnabled] = React.useState(false);
  const [listInvoicesReturned, setListInvoicesReturned] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const itemsWithPricingRules = itemDetails?.filter((item) =>
    Object.keys(item).includes("pricing_rules")
  );

  const handleOpen = (action) => {
    setAction(action);
    setKeyboardVisibility(true);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    } else if (button === "{bksp}") {
      action == "remise" ? handleBackspaceRemise() : handleBackspace();
    }
  };

  const handleBackspace = () => {
    const updatedInput = searchTerm.slice(0, searchTerm.length - 1); // Remove the character before the cursor
    setSearchTerm(updatedInput);
  };

  const handleBackspaceRemise = () => {
    const updatedInput = value.slice(0, value.length - 1); // Remove the character before the cursor
    setValue(updatedInput);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
    setCapsLockEnabled(false);
  };

  useEffect(() => {
    if (globalDefaults?.default_company)
      dispatch(fetchAvoirList(globalDefaults?.default_company));
  }, [globalDefaults?.default_company]);

  const handleChangeSearchCard = (event) => {
    setSearchCard(event.target.value);
    Array.isArray(cardsList) &&
      cardsList.length != 0 &&
      setFilteredGiftCard(
        GlobalfilterData(cardsList, event.target.value, [
          "name",
          "valid_from",
          "valid_upto",
          "coupon_name",
          "coupon_code",
          "pricing_rule",
          "customer",
        ])
      );
  };

  const handleOpenEmail = (row) => () => {
    setOpenSendCard({ open: true, row: row });
    dispatch(LoadPricingRuleByName(row?.pricing_rule));
  };

  const handleCloseEmail = () => {
    setOpenSendCard({ open: false });
    setError(null);
    setEmail("");
  };

  const handleCancel = () => {
    setOpenListCard(false);
  };

  const handleClose = () => {
    setOpenCardModal(false);
  };

  const handleOpenReport = () => {
    setOpenListCard(true);
  };

  const ChangeMotifs = (event) => {
    if (event.target.value === "Others") {
      setMotifs("");
      setIsOthers(true);
    } else {
      if (returnReasonList?.some((obj) => obj?.name === event.target.value)) {
        setMotifs(event.target.value);
        setIsOthers(false);
      } else {
        setMotifs(event.target.value);
      }
    }
  };

  const handleDisc = (value) => {
    SetPercentDiscount(Math.trunc(value));
    setOpenHoldModal(false);
  };

  const loadGiftCards = () => {
    dispatch(loadCouponCodes());
  };

  const handleOpenPrint = (row) => () => {
    setState({ open: true, row: row });
    dispatch(LoadPricingRuleByName(row?.pricing_rule));
  };

  const handleClosePrint = () => {
    setState({ open: false });
  };

  const handleChange = (e) => {
    if (!isNaN(e.target.value)) {
      setValue(e.target.value);
    }
  };

  const handleChangeEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError(t("common:InvalidEmail"));
    } else {
      setError(null);
    }
    setEmail(e.target.value);
  };
  const addDisc = () => {
    if (value <= 100) {
      handleDisc(value);
      setValue(null);
    } else {
      setValue(null);
      setOpenHoldModal(false);
    }
  };

  const handleCancelCheckout = () => {
    talonProps.CancelCheckoutData();
    setAlertModal(false);
    setCodeCoupon("");

    // clear loyalty data
    appliedLoyaltyCard && dispatch(UpdateAppliedLoyCard(null));
    loyaltyProgramDetails && dispatch(SetLoyaltyProgramDetails(null));
  };

  const handleRecentOrders = async () => {
    setIsLoadingOrders(true);
    setOpenRecentModal(true);
    try {
      const data = {
        search_term: "",
        status: status,
        limit:1000,
      };
      await Promise.all([
        dispatch(getRecentOrders(JSON.stringify(data))),
        getPosInvoiceList()
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const filteredOrders = React.useMemo(() => {
    if (!Array.isArray(recentOrdersList) || !Array.isArray(posInvoiceList)) {
      return [];
    }

    const draftInvoice = new Set(posInvoiceList.map(draft => draft.name));
    return recentOrdersList.filter(order => !draftInvoice.has(order.name));
  }, [recentOrdersList, posInvoiceList]);

  useEffect(() => {
    if (openRecentModal || openReturnMngModal) {
      getPosInvoiceList();
    }
  }, [openRecentModal, openReturnMngModal]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    setFilteredRecentOrders(
      GlobalfilterData(filteredOrders, event.target.value, [
        "name",
        "grand_total",
        "customer",
        "posting_date",
      ])
    );
  };

  const onChange = (input) => {
    setSearchTerm(input);
    keyboard.current && keyboard.current.setInput(input);
    setFilteredRecentOrders(
      GlobalfilterData(filteredOrders, input, [
        "name",
        "grand_total",
        "customer",
        "posting_date",
      ])
    );
  };

  const onChangeRemise = (input) => {
    keyboard.current && keyboard.current.setInput(input);
    if (!isNaN(input)) {
      setValue(input);
    }
  };

  const handleSendEmail = () => {
    const data = {
      openSendCard: openSendCard?.row,
      rate: pricingRuleClient?.discount_amount,
    };
    data && dispatch(SendGiftCardEmail(email, data));
    handleCloseEmail();
  };

  const handleOpenReturnModal = async () => {
    setIsLoadingOrders(true);
    setOpenReturnMngModal(true);
    try {
      dispatch(SetAvoirToPrint(null));
      dispatch(fetchAvoirList(globalDefaults?.default_company));
      setStatus("Paid");
      const data = {
        search_term: "",
        status: "Paid",
        limit:1000,
      };
      await Promise.all([
        dispatch(getRecentOrders(JSON.stringify(data))), 
        getPosInvoiceList(),
        getInvoicesReturned()
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingOrders(false);
    }
  };
  const getInvoicesReturned = async () => {
    const resinvoicesReturned = await loadInvoicesReturnedData(
      globalDefaults?.default_company
    );
    setListInvoicesReturned(resinvoicesReturned?.data);
    setLoading(false);
  };

  useEffect(() => {
    const data = {
      search_term: "",
      status: status,
      limit:1000,
    };
    dispatch(getRecentOrders(JSON.stringify(data)));

    
  }, [status]);

  const handleClick = (id) => {
    setOpenReturnModal(true);
    setOpenReturnMngModal(false);
    dispatch(manageReturn(id)); //creact returnedInvoice
  };

  const handleCloseModal = () => {
    setOpenRecentModal(false);
    setOpenReturnMngModal(false);
    setOpenPrestationModal(false);
    setSearchTerm("");
  };

  useEffect(() => {
    if (globalDefaults?.default_company) gettax();
  }, [globalDefaults?.default_company]);
  useEffect(() => {
    itemprestation();
  }, []);
  const gettax = async () => {
    const itemsReqData = {
      doctype: "Account",
      fields: [
        "`tabAccount`.`name`",
        "`tabAccount`.`docstatus`",
        "`tabAccount`.`account_name`",
        "`tabAccount`.`account_number`",
        "`tabAccount`.`disabled`",
        "`tabAccount`.`value`",
      ],
      filters: [
        ["Account", "company", "=", globalDefaults?.default_company],
        ["Account", "account_type", "=", "Chargeable"],
      ],
      order_by: "`tabAccount`.`disabled` asc",
    };
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URI}/api/get-list`,
      itemsReqData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      }
    );
    if (Array.isArray(response.data)) {
      setListTax(response.data);
    }
  };
  const itemprestation = async () => {
    const itemsReqData = {
      doctype: "Item",
      fields: ["*"],
      filters: [
        ["Item", "item_group", "=", "Services"],
        ["Item", "is_stock_item", "=", 0],
        ["Item", "disabled", "=", 0],
      ],
    };
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URI}/api/get-list`,
      itemsReqData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      }
    );
    if (Array.isArray(response.data)) {
      setListItemPres(response.data);
    }
  };


  const tax = (
    <ClickAwayListener onClickAway={() => setOpenTaxModal(false)}>
      <div className={classes.paper}>
        <Typography
          align={"center"}
          color={"primary"}
          style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
        >
          {t("checkout:selectTax")}
        </Typography>
        <Box mt={3}>
          <Grid container spacing={2} justifyContent="center">
            {listTax.map((el, i) => (
              <Grid item xs={4}>
                <Button
                  disabled={el?.disabled == 1}
                  onClick={async () => {
                    await dispatch(SetTaxSales(el));
                    setOpenTaxModal(false);
                  }}
                  className={
                    taxsales && taxsales?.name == el?.name
                      ? classes.buttonvalid
                      : classes.button
                  }
                  variant="outlined"
                  color="primary"
                >
                  <Grid container direction="column" alignContent="center">
                    <Grid item style={{ lineHeight: "1em", fontSize: 14 }}>
                      {el?.account_name}
                      <br />
                      <br />
                      {el?.value}
                      {globalDefaults?.default_currency}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={6} ml={12}>
          <Grid container spacing={4} justifyContent="flex-end">
            <Grid item>
              <Button
                ml={9}
                color={"primary"}
                variant="contained"
                className={classes.inputForm}
                onClick={async () => {
                  await dispatch(SetTaxSales(null));
                  setOpenTaxModal(false);
                }}
                disabled={!taxsales || JSON.stringify(taxsales) == ""}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ClickAwayListener>
  );
  const remise = (
    <ClickAwayListener onClickAway={() => setOpenHoldModal(false)}>
      <div className={classes.paper}>
        <Typography
          align={"center"}
          color={"primary"}
          style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
        >
          {t("checkout:selectDiscount")}
        </Typography>
        <Box mt={3}>
          <Grid container spacing={5} justifyContent="center">
            {/* **********RETAIL-530 : discount synchronization****************  */}
            {Array.isArray(discounts) &&
              discounts.map((disc, i) => (
                <Grid
                  onClick={() => handleDisc(disc?.discount_value)}
                  item
                  key={i}
                >
                  <div className={classes.bar}>
                    <div style={{ position: "relative" }}>
                      <span className={classes.span}>
                        {Math.trunc(parseFloat(disc.discount_value))}%
                      </span>
                      <CircularProgress
                        cursor="pointer"
                        color={"primary"}
                        size={90}
                        variant="determinate"
                        value={100 - parseFloat(disc.discount_value)}
                      />
                    </div>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <FormControl className={classes.formControl} variant="outlined">
                <OutlinedInput
                  className={classes.inputForm}
                  autoComplete="off"
                  id="outlined-adornment-weight"
                  placeholder={t("checkout:plcHolDiscount")}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  labelWidth={0}
                  value={value}
                  onChange={(e) => handleChange(e)}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                color={"primary"}
                variant="contained"
                className={classes.inputForm}
                onClick={addDisc}
                disabled={!value || value >= 101}
              >
                {t("common:select")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                className={classes.inputForm}
                onClick={() => handleDisc(0)}
              >
                {t("Annuler la remise")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ClickAwayListener>
  );
  const [selectedItem, setSelectedItem] = useState(null);

    // ADD ITEM Service
  const handleAddService = async(item) => {

    if (item?.item_code != null && isExistInPOS(item.item_code)) {
      var discountedProducts =
        pricingRules && pricingRules.items
          ? pricingRules.items.map((i) => i.item_code)
          : null;
      var data = suggestion.find((s) => s.item_code == item.item_code);

      if (data) {
        const match = productsPos.find(
          (pos) => pos.item_code === data.item_code
        );

        if (match) {
          data = {
            ...data,
            ...match,
          };
        }
      }

      const ItemIndex = rows.findIndex((s) => s.item_code == item.item_code);
      if (ItemIndex != -1) {
        
          rows[ItemIndex].quantity = parseInt(rows[ItemIndex].quantity) + 1;
          rows[ItemIndex].qty = parseInt(rows[ItemIndex].qty) + 1;
          rows[ItemIndex].price_list_rate = parseFloat(
            rows[ItemIndex].price_list_rate
              ? rows[ItemIndex].price_list_rate
              : rows[ItemIndex].standard_rate
          );
          rows[ItemIndex].net_rate =
            (rows[ItemIndex].price_list_rate -
              (rows[ItemIndex].price_list_rate *
                parseFloat(rows[ItemIndex].discount)) /
                100) /
            currencyExchangeRate;
          rows[ItemIndex].net_amount =
            rows[ItemIndex].net_rate * rows[ItemIndex].quantity;
          rows[ItemIndex].discount_amount =
            (rows[ItemIndex].price_list_rate - rows[ItemIndex].net_rate) *
            rows[ItemIndex].quantity;
        
      } else {
        data.discount = 0;
        data.quantity = 1;
        data.qty = 1;
        data.price_list_rate = data.price_list_rate
          ? parseFloat(data.price_list_rate)
          : parseFloat(data.standard_rate);
        data.net_rate =
          (data.price_list_rate -
            (data.price_list_rate * parseFloat(data.discount)) / 100) /
          currencyExchangeRate;
        data.net_amount = data.net_rate * data.quantity;
        data.discount_amount =
          (data.price_list_rate - data.net_rate) * data.quantity;
        if (
          discountedProducts &&
          discountedProducts.includes(data.item_code) &&
          pricingRules?.discount_percentage != 0
        ) {
          data.discount = pricingRules?.discount_percentage;
          data.net_rate =
            (data.price_list_rate -
              (data.price_list_rate *
                parseFloat(pricingRules?.discount_percentage)) /
                100) /
            currencyExchangeRate;
          data.net_amount = data.net_rate * data.quantity;
        } else if (
          discountedProducts &&
          discountedProducts.includes(data.item_code) &&
          pricingRules?.discount_amount != 0
        ) {
          data.discount_amount = pricingRules?.discount_amount;
          data.net_rate = data.price_list_rate - pricingRules?.discount_amount;
          data.net_amount = data.net_rate * data.quantity;
        }
        rows.push(data);
         props.initialLines.splice(-1);
      }
      dispatch(UpdateProducts(rows));
      loadItemDetails(rows);
      const totalAmount = props.GetTotalPrice(rows);
      const taxAmount =
        totalAmount -
        rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
      dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
      
    }
   };


  const isExistInPOS = (item_code) => {
    if (
      productsPos 
    ) {
      return true;
    } else {
      return false;
    }
  };

  const loadItemDetails = (items) => {
    items?.forEach((item) => {
      let doc = {
        doctype: "POS Invoice",
        company: globalDefaults.default_company,
        posting_date: formatDate(new Date(), "YYYY-MM-DD"),
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        ignore_pricing_rule: 0,
        party_account_currency: globalDefaults.default_currency,
        items: [
          {
            doctype: "POS Invoice Item",
            item_group: item.item_group,
            parentfield: "items",
            parenttype: "POS Invoice",
            qty: item.qty,
            item_code: item.item_code,
          },
        ],
        pos_profile: cashier,
        set_warehouse: store,
        customer_name: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
      };
      let args = {
        item_code: item.item_code,
        set_warehouse: store,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        company: globalDefaults.default_company,
        ignore_pricing_rule: 0,
        doctype: "POS Invoice",
        pos_profile: cashier,
      };
      dispatch(loadItemDetail(args, doc));
    });
  };

  const isReservedInPOS = (item_code) => {
    let totalQtyReserved = 0;
    hold &&
      hold
        ?.filter((element) => element?.payment_status === "partly paid")
        ?.forEach((element) => {
          if (element?.amount?.payments.length > 0) {
            const reservedProducts = element?.products?.filter(
              (el) => el.item_code === item_code
            );

            reservedProducts?.forEach((reservedProduct) => {
              totalQtyReserved += reservedProduct.qty;
            });
          }
        });

    return totalQtyReserved;
  };

 
  const itemPrestation = (
    <ClickAwayListener onClickAway={() => setOpenPrestationModal(false)}>
      <div className={classes.paper}>
        <Typography
          align={"center"}
          color={"primary"}
          style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
        >
          {t("Selectionner Produit Service")}
        </Typography>
        <Box mt={3}>
          <Grid container spacing={2} justifyContent="center">
            {listItemPres.map((el, i) => (
              <Grid item xs={4}>
                <Button
                  disabled={el?.enabled == 1}
                  onClick={async () => {
                    await dispatch(SetTaxSales(el));
                    setSelectedItem(el);
                    setOpenTaxModal(false);
                  }}
                  className={
                    taxsales && taxsales?.name == el?.name
                      ? classes.buttonvalid
                      : classes.button
                  }
                  variant="outlined"
                  color="primary"
                >
                  <Grid container direction="column" alignContent="center">
                    <Grid item style={{ lineHeight: "1em", fontSize: 14 }}>
                      {el?.item_name}
                      <br />
                      <br />
                      {el?.value}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={6} ml={12}>
          <Grid container spacing={4} justifyContent="flex-end">
            <Grid item>
              <Button
                ml={9}
                color="primary"
                variant="contained"
                className={classes.inputForm}
                onClick={() => {
                  handleAddService(selectedItem);
                  setOpenPrestationModal(false);
                }}
              >
                Ajouter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ClickAwayListener>
  );

  const card = (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
      >
        {t("clients:addGiftCard")}
      </Typography>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Link
          component="button"
          className={classes.link}
          variant="body2"
          onClick={() => {
            setOpenListCard(true);
            // setOpenCardModal(false);
            loadGiftCards();
          }}
        >
          {t("checkout:RapportGiftCard")}
        </Link>
        {/* <IconButton variant="contained" component="label">
          <SystemUpdateAltIcon variant="outlined" color="primary" />
          <input
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
            id="xlsx-input"
            type="file"
            hidden
          />
        </IconButton> */}
      </Box>
      <Box mt={2}>
        <GiftCardForm
          isClient={false}
          isCheckout={true}
          handleClose={handleClose}
          handleOpenReport={handleOpenReport}
          loadGiftCards={loadGiftCards}
        />
      </Box>
    </div>
  );

  const PreviewGiftCard = (
    <div>
      <CadeauComponentToPrint handleClose={handleClosePrint} state={state} />
    </div>
  );

  const recentOrders = (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
      >
        {t("checkout:CRecent")}
      </Typography>
      <InputLabel className={classes.label}>
        {t("common:rechercher")}
      </InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <OutlinedInput
          autoComplete="off"
          id="outlined-adornment-weight"
          value={searchTerm}
          name="SearchTerm"
          placeholder="Client, Ref, Date, Grand Total..."
          endAdornment={
            <>
              <KeyboardIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleOpen("recentOrder")}
              />

              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            </>
          }
          onChange={handleChangeSearchTerm}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          labelWidth={0}
        />
      </FormControl>
      <InputLabel className={classes.label}>{t("common:statut")}</InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={status}
          onChange={handleStatusChange}
          label="Statut"
          input={
            <OutlinedInput
              align="left"
              name="Statut"
              id="demo-simple-select-outlined-label"
            />
          }
        >
          <MenuItem key={0} value="Paid" align="left" className={classes.label}>
            {t("checkout:paye")}
          </MenuItem>
          <MenuItem
            key={1}
            value="Consolidated"
            align="left"
            className={classes.label}
          >
            {t("checkout:consolidé")}
          </MenuItem>
          <MenuItem
            key={2}
            value="Return"
            align="left"
            className={classes.label}
          >
            {t("checkout:Return")}
          </MenuItem>
        </Select>
      </FormControl>
      <RecentOrdersTable
        data={searchTerm == "" ? filteredOrders : filteredRecentOrders}
      />
      <Button
        style={{ marginTop: 10 }}
        variant="contained"
        color="secondary"
        onClick={handleCloseModal}
      >
        {t("common:fermer")}
      </Button>
    </div>
  );

  const GiftCardEmail = (
    <ClickAwayListener>
      <div className={classes.paper} style={{ width: 350 }}>
        <Typography align={"center"} color={"primary"}>
          {t("checkout:EnvoiGiftCard")}
        </Typography>
        <Box mt={3} justifyContent="center" display="flex">
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              placeholder={t("common:email")}
              value={email}
              name="email"
              onChange={handleChangeEmail}
            />
          </FormControl>
        </Box>
        <Box justifyContent="center" display="flex">
          {error && <CustomAlert severity="error" message={error} />}
        </Box>

        <Box mt={1} justifyContent="right" display="flex">
          <Button
            color="primary"
            variant="contained"
            onClick={handleSendEmail}
            disabled={email == 0 ? true : error != null ? true : false}
          >
            {t("common:envoyer")}
          </Button>
          <Button color="primary" onClick={handleCloseEmail}>
            {t("common:annuler")}
          </Button>
        </Box>
      </div>
    </ClickAwayListener>
  );

  return (
    <div>
      <Grid container spacing={1}>
        {JSON.parse(
          themes_db?.find(
            (theme_db) => theme_db?.theme_id === activeTheme?.theme_id
          )?.freebuttons || null
        )?.map((freebutton) => {
          switch (freebutton?.button_id) {
            case 1: //remise
              return (
                <>
                  {freebutton?.activated && (
                    <Grid item xs={6}>
                      <Button
                        onClick={() => setOpenHoldModal(true)}
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                      >
                        <Grid
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item>
                            <RemoveCircleIcon />
                          </Grid>
                          <Grid item>{t("common:remise")}</Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </>
              );
            case 2: //couponCode
              return (
                <>
                  {freebutton?.activated && (
                    <Grid item xs={6}>
                      <Button
                        className={classes.button}
                        onClick={() => setOpenCouponcode(true)}
                        variant="outlined"
                        color="primary"
                      >
                        <Grid
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item>
                            <LoyaltyOutlinedIcon
                              style={{
                                height: 30,
                                width: 30,
                                marginBottom: -5,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            style={{ lineHeight: "1em", fontSize: 14 }}
                          >
                            {t("checkout:couponCode")}
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </>
              );
            case 3: //CRecent
              return (
                <>
                  {freebutton?.activated && (
                    <Grid item xs={6}>
                      <Button
                        className={classes.button}
                        onClick={() => handleRecentOrders()}
                        variant="outlined"
                        color="primary"
                      >
                        <Grid
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item>
                            <YoutubeSearchedForIcon
                              style={{
                                height: 30,
                                width: 30,
                                marginBottom: -5,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            style={{ lineHeight: "1em", fontSize: 14 }}
                          >
                            {t("checkout:CRecent")}
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </>
              );
            case 4: //GRetour
              return (
                <>
                  {freebutton?.activated && (
                    <Grid item xs={6}>
                      <Button
                        className={classes.button}
                        onClick={handleOpenReturnModal}
                        variant="outlined"
                        color="primary"
                      >
                        <Grid
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item>
                            <AssignmentReturnIcon
                              style={{
                                height: 30,
                                width: 30,
                                marginBottom: -5,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            style={{ lineHeight: "1em", fontSize: 14 }}
                          >
                            {t("checkout:GRetour")}
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </>
              );
            case 5: //annulation
              return (
                <>
                  {freebutton?.activated && (
                    <Grid item xs={6}>
                      <Button
                        onClick={() =>
                          products && products.length != 0
                            ? setAlertModal(true)
                            : handleCancelCheckout()
                        }
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                      >
                        <Grid
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item>
                            <DeleteSweepIcon />
                          </Grid>
                          <Grid item>{t("checkout:annulation")}</Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </>
              );
            case 6: // loyalty
              return <>{freebutton?.activated && <LoyaltyCard />}</>;
            case 7: //pricingrules
              return (
                <>
                  {freebutton?.activated && (
                    <Grid item xs={6}>
                      <Button
                        onClick={() => setOpenPricingRules(true)}
                        className={
                          itemsWithPricingRules?.length !== 0
                            ? classes.activatedButton
                            : classes.button
                        }
                        variant="outlined"
                        color="primary"
                      >
                        <Grid
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item>
                            <Badge
                              badgeContent={
                                itemsWithPricingRules?.length !== 0
                                  ? itemsWithPricingRules?.length
                                  : null
                              }
                              color="error"
                            >
                              <AccountBalanceWalletIcon />
                            </Badge>
                          </Grid>
                          <Grid
                            item
                            style={{ lineHeight: "1em", fontSize: 14 }}
                          >
                            {t("checkout:PricingRule")}
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </>
              );
            default:
           }
        })}

        <Grid item xs={6}>
          <Button
            onClick={() => setOpenPrestationModal(true)}
            className={classes.button}
            variant="outlined"
            color="primary"
          >
            <Grid container direction="column" alignContent="center">
              <Grid item>
                <RoomServiceIcon
                  style={{ height: 30, width: 30, marginBottom: -5 }}
                />
              </Grid>
              <Grid item style={{ lineHeight: "1em", fontSize: 14 }}>
                {t("product:itemPrestation")}
              </Grid>
            </Grid>
          </Button>
        </Grid>
        {/*     <Grid item xs={6}>
          <Button
            onClick={() => setOpenCardModal(true)}
            className={classes.button}
            variant="outlined"
            color="primary"
          >
            <Grid container direction="column" alignContent="center">
              <Grid item>
                <CardGiftcardIcon
                  style={{ height: 30, width: 30, marginBottom: -5 }}
                />
              </Grid>
              <Grid item style={{ lineHeight: "1em", fontSize: 14 }}>
                {t("checkout:giftCard")}
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => setOpenTaxModal(true)}
            className={classes.button}
            variant="outlined"
            color="primary"
          >
            <Grid container direction="column" alignContent="center">
              <Grid item>
                <MonetizationOnIcon
                  style={{ height: 30, width: 30, marginBottom: -5 }}
                />
              </Grid>
              <Grid item style={{ lineHeight: "1em", fontSize: 14 }}>
                {t("checkout:taxes")}
              </Grid>
            </Grid>
          </Button>
        </Grid> */}
      </Grid>
      <Modal
        open={openTaxModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {tax}
      </Modal>
      <Modal
        open={openPrestationModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {itemPrestation}
      </Modal>
      <Modal
        open={openHoldModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {remise}
      </Modal>
      <Modal
        open={openCouponcode}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Couponcode
          setOpenCouponcode={setOpenCouponcode}
          classes={classes}
          pricingRules={pricingRules}
          codeCoupon={codeCoupon}
          setCodeCoupon={setCodeCoupon}
        />
      </Modal>
      <AlertModal
        setOpenModal={setAlertModal}
        handleCancel={handleCancelCheckout}
        openModal={alertModal}
        products={products}
        title="Annuler le Commande ?"
      />
      <Modal
        open={openRecentModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {recentOrders}
      </Modal>
      <Modal
        open={openReturnModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ReturnForm
          setOpenReturnModal={setOpenReturnModal}
          motifs={motifs}
          setReturnOperation={setReturnOperation}
          setLoading={setLoading} /* name={} */
        />
      </Modal>
      <Modal
        open={openReturnMngModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Salesreturnsmanagement
          setOpenReturnMngModal={setOpenReturnMngModal}
          searchTerm={searchTerm}
          classes={classes}
          tableClasses={tableClasses}
          handleStatusChange={handleStatusChange}
          handleOpen={handleOpen}
          handleChangeSearchTerm={handleChangeSearchTerm}
          status={status}
          motifs={motifs}
          ChangeMotifs={ChangeMotifs}
          isLoadingOrders={isLoadingOrders}
          filteredOrders={filteredOrders}
          filteredRecentOrders={filteredRecentOrders}
          handleCloseModal={handleCloseModal}
          handleClick={handleClick}
          listInvoicesReturned={listInvoicesReturned}
          loading={loading}
          isOthers={isOthers}
        />
      </Modal>
      <Modal
        open={openCardModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        hidden={openListCard}
      >
        {card}
      </Modal>
      <Dialog
        open={openListCard}
        onClose={handleCancel}
        maxWidth="md"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle align={"center"}>
          <Typography
            align={"center"}
            color={"primary"}
            style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
          >
            Listes des cartes cadeaux
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              autoComplete="off"
              id="outlined-adornment-weight"
              value={searchCard}
              name="searchCard"
              placeholder="Client, Nom, Code, Date..."
              endAdornment={
                <InputAdornment position="end">
                  <span className="icon-search" />
                </InputAdornment>
              }
              onChange={handleChangeSearchCard}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </DialogTitle>
        <DialogContent>
          <Box
            className={tableClasses.paper}
            justifyContent="center"
            display="flex"
            flexDirection="column"
          >
            <Table className={tableClasses.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={tableClasses.tablecellHeader}>
                    {" "}
                    {t("common:name")}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tablecellHeader}
                    align="left"
                  >
                    {t("checkout:couponCode")}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tablecellHeader}
                    align="left"
                  >
                    {t("checkout:PricingRule")}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tablecellHeader}
                    align="left"
                  >
                    {t("checkout:ValidFrom")}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tablecellHeader}
                    align="left"
                  >
                    {t("checkout:ValidUntil")}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tablecellHeader}
                    align="left"
                  >
                    {t("common:statut")}
                  </TableCell>
                  <TableCell
                    className={tableClasses.tablecellHeader}
                    align="center"
                  >
                    {t("common:Action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchCard == ""
                  ? Array.isArray(cardsList) &&
                    cardsList.length != 0 &&
                    cardsList
                      .filter((s) => s.coupon_type == "Gift Card")
                      .map((row) => (
                        <TableRow className={tableClasses.tableRow}>
                          <TableCell
                            className={clsx(
                              tableClasses.tableCell,
                              classes.noWrap
                            )}
                            align="left"
                          >
                            {row.coupon_name}
                          </TableCell>
                          <TableCell
                            className={clsx(
                              tableClasses.tableCell,
                              classes.noWrap
                            )}
                            align="left"
                          >
                            {row.coupon_code}
                          </TableCell>
                          <TableCell
                            className={tableClasses.tableCell}
                            align="left"
                          >
                            {row.pricing_rule}
                          </TableCell>
                          <TableCell
                            className={tableClasses.tableCell}
                            align="left"
                          >
                            {formatDate(row.valid_from, "YYYY-MM-DD")}
                          </TableCell>
                          <TableCell
                            className={clsx(
                              tableClasses.tableCell,
                              classes.noWrap
                            )}
                            align="left"
                          >
                            {formatDate(row.valid_upto, "YYYY-MM-DD")}
                          </TableCell>
                          <TableCell
                            className={clsx(
                              tableClasses.tableCell,
                              classes.noWrap
                            )}
                            align="left"
                          >
                            {row.valid_upto >=
                            formatDate(new Date(), "YYYY-MM-DD")
                              ? "activée"
                              : "consommée"}
                          </TableCell>
                          <TableCell
                            className={clsx(
                              tableClasses.tableCell,
                              classes.noWrap
                            )}
                            align="center"
                          >
                            <IconButton aria-label="print">
                              <PrintIcon
                                color="primary"
                                onClick={handleOpenPrint(row)}
                              />
                            </IconButton>
                            <IconButton aria-label="print">
                              <EmailIcon
                                color="primary"
                                onClick={handleOpenEmail(row)}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  : filteredGiftCard &&
                    filteredGiftCard.map((row) => (
                      <TableRow className={tableClasses.tableRow}>
                        <TableCell
                          className={clsx(
                            tableClasses.tableCell,
                            classes.noWrap
                          )}
                          align="left"
                        >
                          {row.coupon_name}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            tableClasses.tableCell,
                            classes.noWrap
                          )}
                          align="left"
                        >
                          {row.coupon_code}
                        </TableCell>
                        <TableCell
                          className={tableClasses.tableCell}
                          align="left"
                        >
                          {row.pricing_rule}
                        </TableCell>
                        <TableCell
                          className={tableClasses.tableCell}
                          align="left"
                        >
                          {formatDate(row.valid_from, "YYYY-MM-DD")}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            tableClasses.tableCell,
                            classes.noWrap
                          )}
                          align="left"
                        >
                          {formatDate(row.valid_upto, "YYYY-MM-DD")}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            tableClasses.tableCell,
                            classes.noWrap
                          )}
                          align="left"
                        >
                          {row.valid_upto >=
                          formatDate(new Date(), "YYYY-MM-DD")
                            ? "activée"
                            : "consommée"}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            tableClasses.tableCell,
                            classes.noWrap
                          )}
                          align="center"
                        >
                          <IconButton aria-label="print">
                            <PrintIcon
                              color="primary"
                              onClick={handleOpenPrint(row)}
                            />
                          </IconButton>
                          <IconButton aria-label="print">
                            <EmailIcon
                              color="primary"
                              onClick={handleOpenEmail(row)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenListCard(false);
              //setOpenCardModal(true);
            }}
            variant="contained"
            color="primary"
          >
            {t("common:fermer")}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={state.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {PreviewGiftCard}
      </Modal>
      <Modal
        open={openSendCard.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {GiftCardEmail}
      </Modal>
      <Modal hideBackdrop={true} open={keyboardVisibility}>
        <ClickAwayListener onClickAway={() => setKeyboardVisibility(false)}>
          <Box className={classes.virtualKeyboard}>
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layout}
              layout={french.layout}
              display={{
                "{capslock}": capsLockEnabled ? "🔒" : "🔓",
              }}
              onChange={
                action == "return" || action == "recentOrder"
                  ? onChange
                  : onChangeRemise
              }
              onKeyPress={onKeyPress}
            />
          </Box>
        </ClickAwayListener>
      </Modal>
      <PricingRules
        openPricingRules={openPricingRules}
        setOpenPricingRules={setOpenPricingRules}
      />
    </div>
  );
};
