import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { symbol } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: 5,
    display: "flex",
    flexDirection: "row",
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    gap: 10,
  },
  margin: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
}));

export const OrderInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "checkout"]);
const open = useSelector((state) => state.Pos.open);
  const detailClient = useSelector((state) => state.Checkout.detailClient);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };
const [posInvoice,setPosInvoice] = React.useState([]);
const [totalJournal, setTotalJournal] = React.useState(0);

const time = moment(open?.period_start_date, "DD-MM-YYYY HH:mm:ss").format("HH:mm:ss");
 
  const currency =
    globalDefaults?.default_currency &&
    symbol(globalDefaults?.default_currency, currencies)?.symbol;
    const getPosInvoice = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "POS Invoice",
          fields: [
            "`tabPOS Invoice`.`grand_total`",
            "`tabPOS Invoice`.`name`",
            "`tabPOS Invoice`.`company`",
            "`tabPOS Invoice`.`currency`",
            "`tabPOS Invoice`.`customer_name`",
          ],
          filters: [
            ["POS Invoice", "posting_date", "Between", [open?.period_start_date, moment().format("YYYY-MM-DD") ]],
           ["POS Invoice","posting_time",">",time],
            ["POS Invoice", "company", "=", open?.company],
            ["POS Invoice", "pos_profile", "=", open?.pos_profile],
          ],
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log('daa',data)

          if (Array.isArray(data)) {
            const sumGrandTotal = data.reduce(
              (accumulator, invoice) => accumulator + (invoice.grand_total || 0),
              0
            );
            const totalTicket =data?.length * 0.1
            setTotalJournal((sumGrandTotal + totalTicket).toFixed(3));
          
             
          }
        })
        .catch((error) => console.error("Error fetching invoices:", error));
    };
     useEffect(() => {
      getPosInvoice();
    }, []);
    
   return (
    <Box className={classes.box}>
      <Box className={classes.margin}>
        <span className={"icon-money"} />
        <span>
          {t("checkout:dashboardInfoDaily")}:{" "}
          {totalJournal}{" "}
          {currency}
        </span>
      </Box>
      <Box className={classes.margin}>
        <span className={"icon-money"} />
        <span>
          {t("checkout:totalUnpaid")}:{" "}
          {detailClient?.__onload?.dashboard_info[0]?.total_unpaid
            ? detailClient?.__onload?.dashboard_info[0]?.total_unpaid.toFixed(3)
            : "0.000"}{" "}
          {currency}
        </span>
      </Box>
      <Box className={classes.margin}>
        <span className={"icon-gift"} />
        <span>
          {t("clients:lyltyPoints")}:{" "}
          {detailClient?.__onload?.dashboard_info[0]?.loyalty_points
            ? detailClient?.__onload?.dashboard_info[0]?.loyalty_points
            : 0}
        </span>
      </Box>
    </Box>
  );
};
