import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import SubRouters from "./helpers/subRouters";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { ThemeProvider } from "@material-ui/core";
import { LightTheme } from "./theme/theme.light";
import { Routes } from "./routes";
import { makeStyles } from "@material-ui/core/styles";
import "./styles/Font.scss";
import "./styles/App.scss";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { SetNotification } from "./store/notification/notification.action";
import io from "socket.io-client";
import localforage from "localforage";
import { Brodcast } from "./helpers/brodcast";
import { RedirectTo } from "./store/page/page.action";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import POSStatusListener from './logoutPos';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  paper: {
    borderRadius: 30,
    padding: theme.spacing(1.5),
    minHeight: "94.7vh",
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation([
    "notification",
    "inventory",
    "payement",
    "buying",
  ]);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const globalColors = useSelector((state) => state.Theme.globalColors);
  const freeButton = useSelector((state) => state.Theme.freeButtonsStyles);
  const products = useSelector((state) => state.Theme.productsStyle);
  const calculator = useSelector((state) => state.Theme.calculatorStyle);
  const selectedProducts = useSelector(
    (state) => state.Theme.selectedProductStyle
  );

  const notification = useSelector((state) => state.Notification.notification);

  const user = useSelector((state) => state.Login.user);

  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(SetNotification(null));
    dispatch(RedirectTo(null));
  };
  useEffect(() => {
    if (user?.role === "Cashier") {
      const STORAGE_KEY = "active-tab";
  
       
      if (localStorage.getItem(STORAGE_KEY)) {
        alert("The site is already open in another tab.");
        window.close(); // Attempt to close the current tab
      } else {
        // Mark this tab as active
        localStorage.setItem(STORAGE_KEY, "true");
      }

      const handleBeforeUnload = () => {
        localStorage.removeItem(STORAGE_KEY);
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        localStorage.removeItem(STORAGE_KEY);
      };
    }
  }, [user?.role]);  
  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      var socket = io(`${process.env.REACT_APP_BROADCASTER_URL}`);
      socket.on("connect", function () {
        let id = process.env.REACT_APP_COMPANY;
        console.log(`socket ${id} connected`);
        socket.emit("join", id);
      });
      const talonProps = Brodcast(dispatch);
      const { BrodcastData } = talonProps;

      socket.on("broadcast", (arg) => {
        if (socket.connected) {
          console.log("notification received");
          BrodcastData(arg);
        }
      });
    }
  }, [localStorage.getItem("user")]);

  const slider = document.querySelector(".scroll");

  const preventClick = (e) => {
    e.stopImmediatePropagation();
    e.preventDefault();
  };
  var isDragged = false;
  let isDown = false;
  let startY;
  let scrollTop;

  slider &&
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      startY = e.pageY - slider.offsetTop;
      scrollTop = slider.scrollTop;
    });
  slider &&
    slider.addEventListener("mouseleave", () => {
      isDown = false;
    });
  slider &&
    slider.addEventListener("mouseup", (e) => {
      isDown = false;
      if (isDragged) {
        slider.addEventListener("click", preventClick);
      } else {
        slider.removeEventListener("click", preventClick);
      }
      isDragged = false;
    });
  slider &&
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const y = e.pageY - slider.offsetTop;
      const walkY = y - startY;
      slider.scrollTop = scrollTop - walkY;
      if (walkY > 30 || walkY < -30) {
        isDragged = true;
      }
    });

  const renderNotification = (notification) => {
    if (notification) {
      if (notification?.values) {
        return t(notification.message) + notification.values;
      } else {
        return t(notification.message);
      }
    }
    return "";
  };

  return (
    <ThemeProvider
      theme={LightTheme({
        freeButton,
        products,
        calculator,
        selectedProducts,
        globalColors,
      })}
    >
      <POSStatusListener />
      <div className="scroll">
        <div className="content">
          <Snackbar
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={notification ? true : false}
            autoHideDuration={2000}
          >
            <Alert
              variant="filled"
              severity={notification ? notification.code : ""}
            >
              {renderNotification(notification)}
            </Alert>
          </Snackbar>

          <Box
            style={{
              backgroundColor: LightTheme(freeButton).palette.secondary.main,
            }}
            className={classes.root}
          >
            <Paper className={classes.paper}>
              <Router>
                <Switch>
                  {Routes.map((props, index) => (
                    <SubRouters key={index} {...props} />
                  ))}
                </Switch>
              </Router>
              {/* <FormControlLabel
                    control={
                      <SwitchButton
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Mode Dark"
                  /> */}
            </Paper>
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;